import { useFormik } from 'formik';
import contactUsSchema from '../../../../modules/validation-schema/contactUsSchema';
import { useApp } from '../../../../context/appContext';
import { Box, TextField } from '@mui/material';
import { useState } from 'react';
import client from '../../../../modules/axios';
import { API } from '../../../../config/path';
import { LoadingButton } from '@mui/lab';

const ContactUsForm = () => {
  const { snackbar, setSnackbar } = useApp();

  const [isDisabled, setIsDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema: contactUsSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      client
        .post(API.CONTACT_US, values)
        .then((res) => {
          if (res.data.success) {
            setIsDisabled(false);
            setSnackbar({
              ...snackbar,
              open: true,
              message: res.data.message,
              type: 'success',
            });

            formik.resetForm();
            return;
          }
        })
        .catch(() => {
          setIsDisabled(false);
          setSnackbar({
            ...snackbar,
            open: true,
            message: 'Internal server error',
            type: 'error',
          });
        });
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mb: 4,
          }}>
          <TextField
            fullWidth
            type="text"
            name="name"
            label="Name"
            required={true}
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />

          <TextField
            fullWidth
            name="email"
            label="Email"
            required={true}
            variant="outlined"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            fullWidth
            name="subject"
            label="Subject"
            required={true}
            variant="outlined"
            type="text"
            value={formik.values.subject}
            onChange={formik.handleChange}
            error={formik.touched.subject && Boolean(formik.errors.subject)}
            helperText={formik.touched.subject && formik.errors.subject}
          />

          <TextField
            fullWidth
            name="message"
            label="Message"
            required={true}
            variant="outlined"
            type="text"
            multiline
            rows={4}
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            sx={{
              '& .MuiInputBase-inputMultiline': {
                p: 0,
              },
            }}
          />
        </Box>

        <Box>
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={isDisabled}
            loading={isDisabled}
            loadingIndicator="Loading…"
            sx={{
              px: 4,
            }}>
            Send
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

export default ContactUsForm;
