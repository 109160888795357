import React from 'react';
import Blogs from '../layout/Blogs';
import SEO from '../modules/seo';

const App = () => {
  return (
    <>
      <SEO
        title="Blogs | Indoaddict"
        description="Indoaddict is a travel agency that offers various tour packages in Indonesia."
        name="Indoaddict"
        type="Indoaddict"
      />
      <Blogs />
    </>
  );
};

export default App;
