import React from 'react';
import SEO from '../modules/seo';
import BlogDetail from '../layout/BlogDetail';

const App = () => {
  return (
    <>
      <SEO
        title="Blogs | Indoaddict"
        description="Indoaddict is a travel agency that offers various tour packages in Indonesia."
        name="Indoaddict"
        type="Indoaddict"
      />
      <BlogDetail />
    </>
  );
};

export default App;
