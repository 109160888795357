import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import TourCard from '../../../../components/Card/TourCard';
import client from '../../../../modules/axios';
import CustomSlick from '../../../../components/CustomSlick';
import { API } from '../../../../config/path';

const RelatedTour = () => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const [topRated, setTopRated] = useState([]);

  const getTopRated = useCallback(async () => {
    try {
      const res = await client.get(API.RECOMMENDED_TOURS);

      const data = res.data.success;

      setTopRated(data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getTopRated();
  }, [getTopRated]);

  return (
    <Container>
      <Box
        sx={{
          mt: 4,
        }}>
        <Typography variant="h2" mb={3}>
          Related Tour Recommendation
        </Typography>

        {/* mobile view */}
        {mobileView && (
          <Box
            sx={{
              display: {
                xs: 'flex',
              },
              flexWrap: 'nowrap',
              overflowX: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              gap: 0,
            }}>
            {topRated.length > 0 &&
              topRated.map(function (tour, i) {
                return (
                  <Box
                    key={i}
                    sx={{
                      minWidth: 220,
                    }}>
                    <TourCard tour={tour} />
                  </Box>
                );
              })}
          </Box>
        )}

        {!mobileView && (
          <Box>
            <CustomSlick slideToShow={4}>
              {topRated.length > 0 &&
                topRated.map(function (tour, i) {
                  return <TourCard tour={tour} key={i} />;
                })}
            </CustomSlick>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default RelatedTour;
