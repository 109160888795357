import { Container, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API } from '../../config/path';
import client from '../../modules/axios';
import BookingCard from './components/BookingCard';
import { getAuthCookie } from '../../context/auth';
import { useApp } from '../../context/appContext';

const BookingDetails = () => {
  const { id } = useParams();
  const { snackbar, setSnackbar } = useApp();
  const [booking, setBooking] = useState(null);

  localStorage.setItem('currOrderId', JSON.stringify(id));

  const getBookingDetails = useCallback(() => {
    client
      .get(`${API.ORDER_DETAILS}/${id}`, {
        headers: {
          Authorization: `Bearer ${getAuthCookie()}`,
        },
      })
      .then((res) => {
        const data = res.data.data;

        setBooking(data);
      })
      .catch(() => {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Internal Server Error',
          type: 'error',
        });
      });
  }, [id]);

  useEffect(() => {
    getBookingDetails();
  }, [getBookingDetails]);

  // console.log('booking', booking);

  return (
    <Container
      sx={{
        pt: 4,
        pb: 10,
      }}>
      <Typography variant="body1" mb={2}>
        Booking Details
      </Typography>

      <BookingCard order={booking} />
    </Container>
  );
};

export default BookingDetails;
