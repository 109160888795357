import { LoadingButton } from '@mui/lab';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import Calendar from '../../../../components/Calendar/LandingCalendar';
import client from '../../../../modules/axios';
import { API } from '../../../../config/path';
import { getAuthCookie } from '../../../../context/auth';
import { useApp } from '../../../../context/appContext';
import dayjs from 'dayjs';

const RescheduleModal = (props) => {
  const { isOpen, handleClose, currOrderId } = props;
  const { snackbar, setSnackbar } = useApp();
  const minDate = dayjs().add(4, 'day').format('YYYY-MM-DD');
  const initPayloadReschedule = {
    order_id: currOrderId,
    reschedule_date: minDate,
    reschedule_reason: '',
  };

  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [payloadReschedule, setPayloadReschedule] = useState(initPayloadReschedule);

  const handleChangeDate = (e) => {
    setPayloadReschedule({
      ...payloadReschedule,
      reschedule_date: e,
    });
    setIsDisabled(false);
  };

  const handleReschedule = () => {
    setIsDisabled(true);
    setIsLoading(true);

    client
      .post(API.RESCHEDULE, payloadReschedule, {
        headers: {
          Authorization: `Bearer ${getAuthCookie()}`,
        },
      })
      .then((res) => {
        if (res.data.error) {
          setSnackbar({
            ...snackbar,
            open: true,
            message: res.data.message,
            type: 'error',
          });

          setIsDisabled(false);
          setIsLoading(false);

          return;
        }

        if (res.data.success) {
          setSnackbar({
            ...snackbar,
            open: true,
            message: res.data.message,
            type: 'success',
          });

          window.location.reload();
        }
      })
      .catch((err) => {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Something went wrong',
          type: 'error',
        });

        setIsDisabled(false);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}>
          <Box
            sx={{
              backgroundColor: '#fff',
              borderRadius: 2,
              p: 4,
              width: '100%',
              maxWidth: 500,
              m: 2,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}>
            <Typography variant="h3">Reschedule</Typography>

            <Box>
              <Typography variant="body1" color={'grey.700'} mb={1}>
                Please select a new date
              </Typography>
              <Calendar minDate={minDate} onChange={handleChangeDate} />
            </Box>

            <Box>
              <Typography variant="body1" color={'grey.700'} mb={1}>
                Reschedule Reason
              </Typography>
              <TextField
                fullWidth
                name="message"
                variant="outlined"
                type="text"
                multiline
                rows={4}
                onChange={(e) => {
                  setPayloadReschedule({
                    ...payloadReschedule,
                    reschedule_reason: e.target.value,
                  });
                }}
                sx={{
                  '& .MuiInputBase-inputMultiline': {
                    p: 0,
                  },
                }}
              />
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: 1,
              }}>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isDisabled}
                loading={isLoading}
                loadingIndicator="Loading…"
                onClick={handleReschedule}
                sx={{
                  px: 4,
                }}>
                Request Reschedule
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default RescheduleModal;
