import { createContext, useContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
    message: '',
    type: 'success',
  });

  const [imgAsset, setImgAsset] = useState([]);

  const getImageAsset = (name) => {
    if (!name || imgAsset.length === 0) return '';

    return imgAsset.filter((v) => v.name === name)[0]?.image ?? '';
  };

  return (
    <AppContext.Provider
      value={{
        snackbar,
        setSnackbar,
        imgAsset,
        setImgAsset,
        getImageAsset,
      }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  return useContext(AppContext);
};
