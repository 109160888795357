import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Hr from '../../../../components/Hr';
import './styles.css';
// import { Helmet } from 'react-helmet-async';

const Reviews = () => {
  return (
    <Container>
      <Box
        sx={{
          my: 4,
        }}>
        <Typography variant="h2" mb={2}>
          Reviews
        </Typography>

        {/* <Helmet> */}
        {/* added _=${Date.now() to force browser download the content on every page load */}
        {/* <script async src={`https://www.jscache.com/wejs?wtype=selfserveprop&uniq=155&locationId=20352946&lang=en_US&rating=true&nreviews=5&writereviewlink=true&popIdx=true&iswide=true&border=false&display_version=2&_=${Date.now()}`} data-loadtrk onload="this.loadtrk=true"></script> */}
        {/* </Helmet> */}

        <div id="TA_selfserveprop155" className="TA_selfserveprop">
          <ul id="OLGsbhfHHr" className="TA_links XpxiFVBsJFTY">
            <li id="BdalhvQ" className="Tq0t1Vi6T">
              <a
                target="_blank"
                href="https://www.tripadvisor.com/Attraction_Review-g14782503-d20352946-Reviews-Indoaddict-Yogyakarta_Yogyakarta_Region_Java.html"
                rel="noreferrer">
                <img
                  src="https://www.tripadvisor.co.id/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-11900-2.svg"
                  alt="TripAdvisor"
                  style={{
                    height: 26,
                  }}
                />
              </a>
            </li>
          </ul>
        </div>
      </Box>
      <Hr />
    </Container>
  );
};

export default Reviews;
