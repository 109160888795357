import { Typography, Container, Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PATH, URL } from '../../../config/path';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useApp } from '../../../context/appContext';

const LinkIcon = (props) => {
  return (
    <Link href={props.href}>
      <Box
        sx={{
          width: 'auto',
          height: {
            xs: 30,
            md: 40,
          },
        }}>
        <img
          src={props.src}
          width={'100%'}
          height={'100%'}
          alt="icon"
          style={{
            objectFit: 'contain',
          }}
        />
      </Box>
    </Link>
  );
};

const LinkMenu = (props) => {
  return (
    <Link
      component={ReactRouterLink}
      to={props.href}
      underline="hover"
      sx={{
        color: '#fff',
        whiteSpace: 'nowrap',
      }}>
      <Typography variant="body1">{props.menu}</Typography>
    </Link>
  );
};

const FooterSection = () => {
  const theme = useTheme();
  const { getImageAsset } = useApp();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.primary.main,
        position: {
          md: 'absolute',
        },
        bottom: 0,
        width: '100%',
        zIndex: 1,
      }}>
      <Container style={{ maxWidth: 'unset' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: {
              xs: 'center',
              md: 'flex-start',
            },
            gap: {
              xs: 4,
            },
            py: 6,
            color: '#fff',
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
          }}>
          {/* social media */}
          <Box>
            <Typography
              variant="body1"
              sx={{
                mb: {
                  xs: 2,
                  md: 3,
                },
                textAlign: {
                  xs: 'center',
                  md: 'left',
                },
              }}>
              Follow us on
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: {
                  xs: 3,
                  md: 2,
                },
              }}>
              <LinkIcon href={URL.TIKTOK} src={getImageAsset('tiktok')} />
              <LinkIcon href={URL.TRIPADVISOR} src={getImageAsset('tripadvisor')} />
              <LinkIcon href={URL.IG} src={getImageAsset('ig')} />
            </Box>
          </Box>

          {/* payment */}
          <Box>
            <Typography
              variant="body1"
              sx={{
                mb: {
                  xs: 2,
                  md: 3,
                },
                textAlign: {
                  xs: 'center',
                  md: 'left',
                },
              }}>
              Payment
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: {
                  xs: 3,
                  md: 2,
                },
              }}>
              <LinkIcon href="#" src={getImageAsset('visa')} />
              <LinkIcon href="#" src={getImageAsset('mastercard')} />
              <LinkIcon href="#" src={getImageAsset('paypal')} />
            </Box>
          </Box>

          {/* page menus */}
          <Box
            sx={{
              width: {
                xs: '100%',
                sm: '40%',
                md: '50%',
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
                borderBottom: '3px solid #fff',
                pb: {
                  xs: 2,
                  md: 3,
                },
              }}>
              <LinkMenu href={PATH.ABOUT_US} menu="About Us" />
              <LinkMenu href={PATH.CONTACT_US} menu="Contact Us" />
              <LinkMenu href={PATH.BLOGS} menu="Blogs" />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default FooterSection;
