import React, { useState } from 'react';
import { useFormik } from 'formik';
import forgotPasswordSchema from '../modules/validation-schema/forgotPasswordSchema';
import { useApp } from '../context/appContext';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Grid, Link, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { API, PATH } from '../config/path';
import client from '../modules/axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link as ReactRouterLink } from 'react-router-dom';

const App = () => {
  const { snackbar, setSnackbar } = useApp();
  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      client
        .post(API.FORGOT_PASSWORD, values)
        .then((res) => {
          setSnackbar({
            ...snackbar,
            open: true,
            message: res.data.success,
            type: 'success',
          });
          navigate(PATH.LOGIN);
        })
        .catch((err) => {
          if (err.response.status === 500) {
            setSnackbar({
              ...snackbar,
              open: true,
              message: 'Internal server error',
              type: 'error',
            });
            return;
          }

          setSnackbar({
            ...snackbar,
            open: true,
            message: err.response.data.error.email[0],
            type: 'error',
          });

          setIsDisabled(false);
        });
    },
  });

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 3,
          border: '1px solid' + grey[300],
          borderRadius: 1,
        }}>
        <Box
          sx={{
            backgroundColor: grey[100],
            py: 1.5,
            px: 2.5,
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}>
            <Link
              component={ReactRouterLink}
              to={PATH.HOME}
              color={'primary'}
              underline="hover"
              align="right">
              <Button
                variant="text"
                color="primary"
                startIcon={<ArrowBackIcon />}
                sx={{
                  py: 0,
                }}>
                Home
              </Button>
            </Link>
            <Typography variant="body1">Forgot Password</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            py: 2,
            px: 2.5,
          }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">Email Address</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={8}>
                <Box
                  sx={{
                    pt: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                  }}>
                  <Button type="submit" variant="contained" disabled={isDisabled}>
                    Request Password Reset
                  </Button>
                  <Link
                    component={ReactRouterLink}
                    to={PATH.LOGIN}
                    color={'primary'}
                    underline="hover">
                    <Typography variant="body1">Login</Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default App;
