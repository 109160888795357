import { Box, Grid, TextField, Typography } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { useFormik } from 'formik';
import React from 'react';
import Number from '../../../../components/Number';
import checkoutActivitySchema from '../../../../modules/validation-schema/checkoutActivitySchema';
import BtnNext from '../NextButton';
import CheckoutSummary from '../CheckoutSummary';

const Activity = (props) => {
  const { currSection, dataCheckout, setDataCheckout, setDisabledPay, productCheckout } = props;

  const formik = useFormik({
    initialValues: {
      first_name_traveler: dataCheckout.first_name_traveler,
      last_name_traveler: dataCheckout.last_name_traveler,
      pickup_loc: dataCheckout.pickup_loc,
      special_req: dataCheckout.special_req,
    },
    validationSchema: checkoutActivitySchema,
    onSubmit: (values) => {
      setDisabledPay(false);
      setDataCheckout({
        ...dataCheckout,
        ...values,
      });
    },
  });

  return (
    <>
      {currSection === 2 && (
        <Box
          sx={{
            border: '1px solid' + green[800],
            p: 2,
            borderRadius: 2,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: 3,
            }}>
            <Number i={2} filled={true} />
            <Typography variant="h3" fontWeight={600}>
              Activity Details
            </Typography>
          </Box>

          <Box mb={2}>
            <CheckoutSummary productCheckout={productCheckout} />
          </Box>

          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                py: 3,
                borderBottom: '1px solid' + grey[300],
              }}>
              <Typography variant="h4" mb={1}>
                Primary traveler (Adult)
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" mb={1}>
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="first_name_traveler"
                    value={formik.values.first_name_traveler}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.first_name_traveler &&
                      Boolean(formik.errors.first_name_traveler)
                    }
                    helperText={
                      formik.touched.first_name_traveler && formik.errors.first_name_traveler
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" mb={1}>
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="last_name_traveler"
                    value={formik.values.last_name_traveler}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.last_name_traveler && Boolean(formik.errors.last_name_traveler)
                    }
                    helperText={
                      formik.touched.last_name_traveler && formik.errors.last_name_traveler
                    }
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{
                py: 3,
                borderBottom: '1px solid' + grey[300],
              }}>
              <Typography variant="h4" mb={2}>
                Pickup location
              </Typography>

              <TextField
                fullWidth
                variant="outlined"
                name="pickup_loc"
                value={formik.values.pickup_loc}
                onChange={formik.handleChange}
                error={formik.touched.pickup_loc && Boolean(formik.errors.pickup_loc)}
                helperText={formik.touched.pickup_loc && formik.errors.pickup_loc}
              />
            </Box>

            <Box
              sx={{
                py: 3,
              }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" mb={2}>
                    Tour language
                  </Typography>
                  <Typography variant="body1">English - Guide</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" mb={1}>
                    Special requirements
                  </Typography>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="special_req"
                    value={formik.values.special_req}
                    onChange={formik.handleChange}
                    placeholder="e.g. dietary needs, accessibility"
                  />
                </Grid>
              </Grid>
            </Box>

            <BtnNext type="submit">Next</BtnNext>
          </form>
        </Box>
      )}

      {currSection !== 2 && (
        <Box
          sx={{
            border: '1px solid' + grey[400],
            p: 2,
            borderRadius: 2,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}>
              <Number i={1} filled={false} />
              <Typography variant="h3" color={grey[700]} fontWeight={600}>
                Activity Details
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Activity;
