import { Box, Container, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import Hr from '../../../../components/Hr';
import client from '../../../../modules/axios';
import ReactHtmlParser from 'html-react-parser';

const Faq = () => {
  const [faqs, setFaqs] = useState([]);

  const getFaqs = useCallback(() => {
    return client
      .get('api/faq')
      .then((res) => {
        setFaqs(res.data.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    getFaqs();
  }, [getFaqs]);

  return (
    <Container>
      <Box
        sx={{
          my: 4,
        }}>
        <Typography variant="h2" mb={3}>
          Frequently Asked Questions
        </Typography>

        <Typography variant="body1" mb={6}>
          The answers provided below are based on answers previously given by the tour provider to
          customers questions
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}>
          {faqs.length > 0 &&
            faqs.map(function (faq, i) {
              return (
                <Box key={i}>
                  <Typography variant="body1" mb={2}>
                    <strong>Q: </strong>
                    {faq.question}
                  </Typography>
                  <Box
                    sx={{
                      backgroundColor: '#f5f5f5',
                      p: {
                        xs: 2,
                        sm: 4,
                      },
                    }}>
                    <Typography variant="body1">
                      <strong>A: </strong> {ReactHtmlParser(faq.answer + '')}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
      <Hr />
    </Container>
  );
};

export default Faq;
