import React from 'react';
import { Box, Link } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { grey } from '@mui/material/colors';
import { PATH } from '../../../config/path';

const MediaControlCard = (props) => {
  return (
    <Link href={`${PATH.PRODUCTS}/${props.slug}`} underline="none">
      <Card
        sx={{
          p: {
            xs: 2,
            sm: 3,
          },
          boxShadow: 'none',
          border: '1px solid' + grey[400],
          borderRadius: 2.5,
          transition: 'all 0.3s ease',
        }}>
        <Grid
          container
          columnSpacing={{
            xs: 0,
            sm: 4,
          }}
          rowSpacing={{
            xs: 2,
            sm: 0,
          }}>
          <Grid item xs={12} sm={4}>
            <CardMedia
              component="img"
              image={props.product_thumbnail}
              alt="Product Thumbnail"
              loading="lazy"
              sx={{
                borderRadius: 2.5,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <Typography
                variant="h3"
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}>
                {props.title}
              </Typography>
              <Box>
                <Typography variant="body1" textAlign={'right'} fontSize={14}>
                  From
                </Typography>
                <Typography variant="h3">${props.start_price}</Typography>
              </Box>
            </Box>

            <Box>
              <p dangerouslySetInnerHTML={{ __html: props.desc }}></p>
            </Box>

            <Box
              sx={{
                display: 'flex',
                columnGap: {
                  xs: 2,
                  sm: 4,
                },
                rowGap: {
                  xs: 2,
                },
                flexWrap: 'wrap',
              }}>
              {props.include_facility?.map((facility, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      gap: 0.5,
                      alignItems: 'center',
                    }}>
                    <Box
                      component={'img'}
                      src={facility.thumbnail}
                      loading="lazy"
                      sx={{
                        width: 'auto',
                        objectFit: 'contain',
                        height: 25,
                      }}
                    />
                    <Typography variant="body1">{facility.name}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
};

export default MediaControlCard;
