import Cookies from 'js-cookie';

export const setAuthCookie = (value) => {
  if (!value) return;

  Cookies.set('IndoaddictToken', value);
};

export const getAuthCookie = () => {
  return Cookies.get('IndoaddictToken');
};

export const destroyAuthCookie = () => {
  Cookies.remove('IndoaddictToken');
};

export const setAuthUser = (value) => {
  if (!value) return;

  localStorage.setItem('IndoaddictUser', JSON.stringify(value));
};

export const getAuthUser = () => {
  const user = localStorage.getItem('IndoaddictUser');

  if (!user) {
    return {
      user_id: null,
      name: null,
    };
  }

  return JSON.parse(user);
};

export const destroyAuthUser = () => {
  localStorage.removeItem('IndoaddictUser');
};
