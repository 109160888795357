import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box } from '@mui/material';
import './style.css';

const GallerySlick = (props) => {
  const { images } = props;
  const settings = {
    className: 'slider',
    variableWidth: false,
    dots: true,
    dotsClass: 'slick-dots slick-thumb',
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: (
      <KeyboardArrowRightIcon
        sx={{
          top: '50%',
          opacity: 0.8,
          mr: 2,
        }}
      />
    ),
    prevArrow: (
      <KeyboardArrowLeftIcon
        sx={{
          left: 0,
          ml: 18,
          top: '50%',
          opacity: 0.8,
        }}
      />
    ),
    customPaging: function (i) {
      return (
        <a href="#image">
          <Box
            component={'img'}
            src={images[i]}
            loading="lazy"
            sx={{
              width: 120,
              height: 80,
              objectFit: 'cover',
              borderRadius: 2,
              backgroundColor: 'black',
              opacity: 0.8,
            }}
          />
        </a>
      );
    },
  };

  return (
    <Box className="gallery-slick">
      <Slider {...settings}>
        {images.map((image, i) => {
          return (
            <Box
              key={i}
              component="img"
              alt="Product image"
              src={image}
              loading="lazy"
              sx={{
                width: '100%',
                height: 500,
                objectFit: 'cover',
                borderRadius: 2,
                backgroundColor: 'grey.200',
              }}
            />
          );
        })}
      </Slider>
    </Box>
  );
};

export default GallerySlick;
