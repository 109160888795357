import React from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Link, Typography } from '@mui/material';
import { PATH } from '../../../../config/path';
import { Link as ReactRouterLink } from 'react-router-dom';

const TopDestinationCard = (props) => {
  const { top_destination } = props;

  return (
    <Link
      component={ReactRouterLink}
      to={`${PATH.DESTINATION}?region=${top_destination.name}`}
      underline="none">
      <Card
        sx={{
          position: 'relative',
          border: 'none',
        }}>
        <CardActionArea sx={[{ '.MuiCardActionArea-focusHighlight': { opacity: 0.4 } }]}>
          <CardMedia
            component="img"
            image={top_destination.city_image}
            alt={top_destination.city_image}
            loading="lazy"
            width={'auto'}
            height={'auto'}
            sx={{
              width: {
                xs: 200,
                md: '100%',
              },
              height: 200,
              objectFit: 'cover',
            }}
          />
          <CardContent
            sx={{
              position: 'absolute',
              zIndex: 1,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              p: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Box>
              <Typography
                variant="h2"
                sx={{
                  color: '#fff',
                  fontSize: {
                    xs: '1.5rem',
                    fontWeight: 500,
                  },
                }}>
                {top_destination.name}
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default TopDestinationCard;
