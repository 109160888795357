import React, { useState } from 'react';
import { Link } from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Box, Button, Dialog, IconButton, List, ListItem, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CircleIcon from '@mui/icons-material/Circle';

const LearnMoreCancellationButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      {/* CANCELLATION BUTTON */}
      <Link
        component={ReactRouterLink}
        to={'#'}
        color="secondary"
        underline="none"
        onClick={handleOpen}>
        Learn more
      </Link>
      {/* END OF CANCELLATION BUTTON */}

      {/* POP UP CANCELLATION */}
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll="body"
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: '100%',
            width: {
              xs: '100%',
              md: '50%',
            },
          },
        }}>
        {/* CLOSE BUTTON */}
        <Box
          sx={{
            px: {
              xs: 1,
              md: 1,
            },
            pt: 1,
          }}>
          <IconButton onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </Box>
        {/* END OF CLOSE BUTTON */}

        {/* CONTENT */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: {
              xs: 3,
              md: 4,
            },
            px: {
              xs: 2,
              md: 10,
            },
            pb: 4,
          }}>
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              fontSize: {
                xs: '1.15rem',
                md: '1.25rem',
              },
            }}>
            Cancelation and Refund Term & Conditions
          </Typography>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
              mb: 4,
            }}>
            <Box>
              <Typography
                variant="h3"
                fontSize={{
                  xs: '1rem',
                }}
                fontWeight={600}>
                A. Applicable Refund Reason
              </Typography>
              <List
                sx={{
                  listStyleType: 'disc',
                  listStylePosition: 'inside',
                }}>
                <ListItemBullet>Self cancellation (change of plan)</ListItemBullet>
                <ListItemBullet>Sickness</ListItemBullet>
              </List>
            </Box>
            <Box>
              <Typography
                variant="h3"
                fontSize={{
                  xs: '1rem',
                }}
                fontWeight={600}>
                B. Refund Policy
              </Typography>
              <List
                sx={{
                  listStyleType: 'disc',
                  listStylePosition: 'inside',
                }}>
                <ListItemBullet>
                  Cancellations made 10 days or more in advance of the event date, will receive a
                  100% refund.
                </ListItemBullet>
                <ListItemBullet>
                  Cancellations made within 7 - 9 days prior to the event date, will be charged 25%
                  of paid amount.
                </ListItemBullet>
                <ListItemBullet>
                  Cancellations made within 5 - 6 days prior to the event date, will be charged 50%
                  of paid amount.
                </ListItemBullet>
                <ListItemBullet>
                  Cancellations made within 3 - 4 days prior to the event date, will be charged 75%
                  of paid amount.
                </ListItemBullet>
                <ListItemBullet>
                  Your booking is not refundable if you request a refund less than 1 (day)s before
                  the selected visit date.
                </ListItemBullet>
                <ListItemBullet>
                  The final refund amount will not include service fees, discount coupons, and/or
                  bank transfer unique code charge.
                </ListItemBullet>
              </List>
            </Box>
            <Box>
              <Typography
                variant="h3"
                fontSize={{
                  xs: '1rem',
                }}
                fontWeight={600}
                sx={{
                  mb: {
                    xs: 1.4,
                  },
                }}>
                C. Refund Process Duration
              </Typography>
              <Typography variant="body1">
                If approved, you will receive your refund within 90 days after refund request is
                submitted. However, it can take shorter or longer, depending on the proccess applied
                by Indoaddict.
              </Typography>
            </Box>
          </Box>

          <Box textAlign={'center'}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClose}
              sx={{
                px: 10,
              }}>
              OK
            </Button>
          </Box>
        </Box>
        {/* END OF CONTENT */}
      </Dialog>
      {/* END OF POP UP CANCELLATION */}
    </>
  );
};

const ListItemBullet = ({ children }) => {
  return (
    <ListItem
      sx={{
        display: 'flex',
        py: 0,
        gap: 1,
        alignItems: 'flex-start',
      }}>
      <Box
        sx={{
          mt: -0.2,
        }}>
        <CircleIcon
          sx={{
            fontSize: '0.45rem',
          }}
        />
      </Box>
      <Box>{children}</Box>
    </ListItem>
  );
};

export default LearnMoreCancellationButton;
