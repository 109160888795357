import { Box, Container, Link, Typography } from '@mui/material';
import React from 'react';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import ChatIcon from '@mui/icons-material/Chat';
import Hr from '../../../../components/Hr';

const Item = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        flexWrap: 'nowrap',
        ...props.sx,
      }}>
      {props.icon}
      {props.children}
    </Box>
  );
};

const Features = (props) => {
  const { product } = props;
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          gap: {
            xs: 1,
            sm: 2,
          },
          justifyContent: 'space-between',
          flexDirection: { xs: 'column', sm: 'row' },
          p: 1,
          my: 1,
          flexWrap: 'wrap',
        }}>
        {product.safety_measures && (
          <Item icon={<SafetyCheckIcon color="secondary" style={{ fontSize: '1.5rem' }} />}>
            <Typography variant="body1">
              <Link href="" color="inherit">
                Safety measure
              </Link>
            </Typography>
          </Item>
        )}
        <Item icon={<AccessTimeIcon color="secondary" style={{ fontSize: '1.5rem' }} />}>
          <Typography variant="body1">{product.duration}</Typography>
        </Item>
        {product.hotel_pickup && (
          <Item icon={<DirectionsCarIcon color="secondary" style={{ fontSize: '1.5rem' }} />}>
            <Typography variant="body1">Hotel pickup offered</Typography>
          </Item>
        )}
        {product.mobile_ticket && (
          <Item icon={<MobileFriendlyIcon color="secondary" style={{ fontSize: '1.5rem' }} />}>
            <Typography variant="body1">Mobile ticket</Typography>
          </Item>
        )}
        {product.offered_in_english && (
          <Item icon={<ChatIcon color="secondary" style={{ fontSize: '1.5rem' }} />}>
            <Typography variant="body1">Offered in English</Typography>
          </Item>
        )}
      </Box>
      <Hr />
    </Container>
  );
};

export default Features;
