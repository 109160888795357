import React from 'react';
import HistoryBooking from '../layout/HistoryBooking';

const App = () => {
  return (
    <>
      <HistoryBooking />
    </>
  );
};

export default App;
