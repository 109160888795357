import HistoryBookingDetails from '../layout/HistoryBookingDetails';

const App = () => {
  return (
    <>
      <HistoryBookingDetails />
    </>
  );
};

export default App;
