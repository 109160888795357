import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import BookingCard from './components/BookingCard';
import { API } from '../../config/path';
import client from '../../modules/axios';
import { getAuthCookie } from '../../context/auth';
import { useApp } from '../../context/appContext';

const ActiveBooking = () => {
  const { snackbar, setSnackbar } = useApp();
  const [activeOrders, setActiveOrders] = useState(null);

  const getActiveOrders = useCallback(() => {
    client
      .get(API.ACTIVE_ORDER, {
        headers: {
          Authorization: 'Bearer ' + getAuthCookie(),
        },
      })
      .then((res) => {
        setActiveOrders(res.data.data);
      })
      .catch((err) => {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Internal Server Error',
          type: 'error',
        });
      });
  }, []);

  useEffect(() => {
    getActiveOrders();
  }, [getActiveOrders]);

  return (
    <Container
      sx={{
        pt: {
          xs: 2,
          md: 4,
        },
        pb: 10,
      }}>
      <Typography variant="body1" mb={2}>
        Active Booking
      </Typography>

      {!activeOrders?.length > 0 && (
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: 'grey.500',
            }}>
            You don't have any active booking
          </Typography>
        </Box>
      )}

      {activeOrders?.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: {
              xs: 2,
              md: 3,
            },
          }}>
          {activeOrders?.map((order, i) => {
            return <BookingCard key={i} order={order} />;
          })}
        </Box>
      )}
    </Container>
  );
};

export default ActiveBooking;
