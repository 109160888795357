import * as yup from 'yup';

const resetPasswordSchema = yup.object().shape({
  new_password: yup
    .string()
    .required('Password is required')
    .min(8, 'The password must be at least 8 characters'),
  password_confirm: yup
    .string()
    .oneOf([yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm Password is required')
    .min(8, 'The password must be at least 8 characters'),
});

export default resetPasswordSchema;
