import { Box, Container, Grid, Typography } from '@mui/material';
import SEO from '../modules/seo';
import { useApp } from '../context/appContext';

const AboutUs = () => {
  const { getImageAsset } = useApp();

  return (
    <>
      <SEO
        title="About Us | Indoaddict"
        description="Indoaddict is a travel agency that offers various tour packages in Indonesia."
        name="Indoaddict"
        type="Indoaddict"
      />

      {/* HERO */}
      <Box
        sx={{
          position: 'relative',
          mb: {
            xs: 3,
            md: 8,
          },
        }}>
        <Box
          component={'img'}
          src={getImageAsset('bg-about')}
          alt="Hero"
          loading="lazy"
          sx={{
            width: '100%',
            height: {
              xs: '20vh',
              md: '60vh',
            },
            objectFit: 'cover',
            backgroundColor: 'grey.300',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: {
              xs: -10,
              md: -20,
            },
            left: 0,
            width: '100%',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              backgroundColor: 'primary.main',
              width: '90%',
            }}>
            <Typography
              variant="h1"
              sx={{
                px: {
                  xs: 2,
                  md: 4,
                },
                py: {
                  md: 1,
                },
                color: 'white',
                fontWeight: 500,
                fontSize: {
                  xs: '1.25rem',
                  md: '2rem',
                },
              }}>
              About Us
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* END OF HERO */}

      <Container
        sx={{
          px: {
            md: 10,
          },
        }}>
        {/* WELCOME */}
        <Grid
          container
          columnSpacing={{
            md: 8,
          }}
          rowSpacing={{
            xs: 2,
          }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'right',
                },
              }}>
              <Typography
                variant="h2"
                sx={{
                  mb: {
                    xs: 2,
                  },
                  fontWeight: 500,
                  fontSize: {
                    md: '2.5rem',
                  },
                  lineHeight: 1.4,
                }}>
                Welcome
                <br /> to
                <br /> Indoaddict
              </Typography>
              <Typography variant="body1">
                Your Gateway to Unforgettable Adventures in Indonesia!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography variant="body1">
                At Indoaddict, we are passionate about sharing the wonders of Indonesia with the
                world. As avid travelers ourselves, we understand that every journey should be an
                unforgettable experience filled with cherished memories. That's why we have curated
                a wide range of tour and travel packages that cater to all kinds of explorers,
                whether you are an adrenaline junkie seeking thrilling escapades, a cultural
                enthusiast craving to immerse in rich traditions, or simply a traveler seeking
                tranquility amidst nature's beauty.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* END OF WELCOME */}

        <Box
          sx={{
            textAlign: 'center',
            position: 'relative',
            my: {
              xs: 4,
              md: 8,
            },
          }}>
          <Box
            component={'img'}
            src={getImageAsset('3-dots')}
            alt="Decoration image"
            loading="lazy"
            sx={{
              width: 'auto',
              height: 24,
              objectFit: 'contain',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: -6,
              left: 0,
              width: '100%',
              height: 'auto',
            }}>
            <Box
              component={'img'}
              src={getImageAsset('curly-dotted-2')}
              alt="Decoration image"
              loading="lazy"
              sx={{
                display: {
                  xs: 'none',
                  md: 'inline',
                },
                width: 'auto',
                height: 68,
                objectFit: 'contain',
                ml: {
                  md: 40,
                },
              }}
            />
          </Box>
        </Box>

        {/* WHY CHOOSE */}
        <Grid
          container
          columnSpacing={{
            md: 8,
          }}
          rowSpacing={{
            xs: 2,
          }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: {
                md: 'none',
              },
            }}>
            <Box>
              <Typography
                variant="h2"
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    md: '2.5rem',
                  },
                  lineHeight: 1.4,
                }}>
                Why
                <br /> Choose
                <br /> Indoaddict?
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'right',
                },
                display: 'flex',
                flexDirection: 'column',
                gap: {
                  xs: 2,
                  md: 4,
                },
              }}>
              <Typography variant="body1">
                <b>Expertise and Local Insight</b> - Our team ensures that our itineraries are
                meticulously designed to showcase the best of the country, capturing its essence and
                diversity.
              </Typography>
              <Typography variant="body1">
                <b>Tailor-Made Itineraries</b> - With Indoaddict, you have the flexibility to
                customize your travel itinerary to suit your preferences, allowing you to experience
                Indonesia exactly the way you want.
              </Typography>
              <Typography variant="body1">
                <b>Safety and Comfort</b> - Your safety and comfort are our top priorities. We
                partner with trusted accommodation providers, experienced guides, and reliable
                transportation services to ensure that your journey is smooth, secure, and
                stress-free.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: {
                xs: 'none',
                md: 'block',
              },
            }}>
            <Box>
              <Typography
                variant="h2"
                sx={{
                  mb: {
                    xs: 2,
                  },
                  fontWeight: 500,
                  fontSize: {
                    md: '2.5rem',
                  },
                  lineHeight: 1.4,
                }}>
                Why
                <br /> Choose
                <br /> Indoaddict?
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* END OF WHY CHOOSE */}

        <Box
          sx={{
            textAlign: 'center',
            position: 'relative',
            my: {
              xs: 4,
              md: 8,
            },
          }}>
          <Box
            component={'img'}
            src={getImageAsset('3-dots')}
            alt="Decoration image"
            loading="lazy"
            sx={{
              width: 'auto',
              height: 24,
              objectFit: 'contain',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: -40,
              left: 0,
              width: '100%',
              height: 'auto',
            }}>
            <Box
              component={'img'}
              src={getImageAsset('curly-dotted-1')}
              alt="Decoration image"
              loading="lazy"
              sx={{
                display: {
                  xs: 'none',
                  md: 'inline',
                },
                width: 'auto',
                height: 120,
                objectFit: 'contain',
                mr: {
                  md: 60,
                },
              }}
            />
          </Box>
        </Box>

        {/* YOUR ADVENTURE */}
        <Grid
          container
          columnSpacing={{
            md: 8,
          }}
          rowSpacing={{
            xs: 2,
          }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'right',
                },
              }}>
              <Typography
                variant="h2"
                sx={{
                  mb: {
                    xs: 2,
                  },
                  fontWeight: 500,
                  fontSize: {
                    md: '2.5rem',
                  },
                  lineHeight: 1.4,
                }}>
                . Indoaddict
                <br /> Your
                <br /> Adventure
                <br /> Awaits!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: {
                  xs: 2,
                  md: 4,
                },
              }}>
              <Typography variant="body1">
                Whether you are a seasoned traveler or setting off on your very first adventure,
                Indoaddict promises to make your Indonesian escapade extraordinary. Let us be your
                travel companion, guiding you through the rich tapestry of Indonesia's landscapes,
                cultures, and traditions. Indulge in the warmth of Indonesian hospitality and create
                lasting memories with Indoaddict.
              </Typography>
              <Typography variant="body1">
                Come and experience the allure of Indonesia with us. Let the adventure begin!
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {/* END OF YOUR ADVENTURE */}

        <Box
          sx={{
            textAlign: 'center',
            position: 'relative',
            my: {
              xs: 4,
              md: 8,
            },
          }}>
          <Box
            component={'img'}
            src={getImageAsset('3-dots')}
            alt="Decoration image"
            loading="lazy"
            sx={{
              width: 'auto',
              height: 24,
              objectFit: 'contain',
            }}
          />
        </Box>
      </Container>
    </>
  );
};

export default AboutUs;
