import Slider from 'react-slick';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import './slick.css';

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <KeyboardArrowRightIcon
      sx={{
        top: '40%',
        opacity: 0.8,
        mr: 6,
      }}
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <KeyboardArrowLeftIcon
      sx={{
        left: 0,
        ml: 4,
        top: '40%',
        opacity: 0.8,
      }}
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    />
  );
};

const CustomSlick = (props) => {
  const { slideToShow, children } = props;

  const settings = {
    className: 'slider',
    variableWidth: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slideToShow,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          infinite: true,
        },
      },
    ],
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default CustomSlick;
