import React from 'react';
import { Box, Link, Typography, Avatar } from '@mui/material';
import { getAuthCookie, getAuthUser } from '../../../../context/auth';
import { useApp } from '../../../../context/appContext';

const CommentCard = (props) => {
  const { getImageAsset } = useApp();

  const { comment } = props;

  const authUser = getAuthUser();

  // console.log('authUser', authUser);
  // console.log('comment', comment);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: {
            xs: 1.5,
            md: 2,
          },
          // bgcolor: 'grey'
        }}>
        <Box>
          <Avatar
            alt="Avatar"
            src={getImageAsset('default-avatar')}
            sx={{
              height: {
                xs: '2rem',
                md: '2.5rem',
              },
              width: {
                xs: '2rem',
                md: '2.5rem',
              },
              bgcolor: '#eeeeee',
            }}
          />
        </Box>

        <Box
          sx={{
            // bgcolor: 'green',
            flexGrow: 1,
          }}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: 500,
              fontSize: {
                xs: '0.95rem',
                md: '1.2rem',
              },
            }}>
            {comment.user_name}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: '0.85rem',
                md: '1rem',
              },
              mb: 1,
            }}>
            {comment.comment}
          </Typography>

          {/* EDIT DELETE BUTTON */}
          {getAuthCookie() && authUser.user_id === comment.id_user && (
            <Box
              sx={{
                // bgcolor: 'yellow',
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
              }}>
              <Link
                href="#"
                underline="always"
                color={'inherit'}
                onClick={(e) => {
                  e.preventDefault();

                  props.setComment(comment);
                  props.setOpen(true);
                }}
                sx={{
                  ':hover': {
                    color: 'primary.main',
                  },
                }}>
                <Typography
                  variant="body1"
                  noWrap
                  sx={{
                    fontSize: {
                      xs: '0.75rem',
                      md: '1rem',
                    },
                  }}>
                  Edit
                </Typography>
              </Link>

              <Link
                href="#"
                underline="always"
                color={'inherit'}
                onClick={(e) => {
                  e.preventDefault();

                  props.handleDeleteComment(comment);
                }}
                sx={{
                  ':hover': {
                    color: 'primary.main',
                  },
                }}>
                <Typography
                  variant="body1"
                  noWrap
                  sx={{
                    fontSize: {
                      xs: '0.75rem',
                      md: '1rem',
                    },
                  }}>
                  Delete
                </Typography>
              </Link>
            </Box>
          )}
          {/* END OF EDIT DELETE BUTTON */}
        </Box>
      </Box>
    </>
  );
};

export default CommentCard;
