import { Container, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useCallback, useEffect, useState } from 'react';
import { API } from '../../config/path';
import { useApp } from '../../context/appContext';
import { getAuthCookie } from '../../context/auth';
import client from '../../modules/axios';
import BookingCard from './components/BookingCard';
import Filters from './components/Filters';

const HistoryBooking = () => {
  const { snackbar, setSnackbar } = useApp();
  const [historyOrders, setHistoryOrders] = useState(null);
  const [filterByDate, setFilterByDate] = useState(null);

  const getActiveOrders = useCallback(() => {
    if (filterByDate !== null) {
      client
        .get(`${API.HISTORY_ORDER}?sd=${filterByDate?.start}&ed=${filterByDate?.end}`, {
          headers: {
            Authorization: 'Bearer ' + getAuthCookie(),
          },
        })
        .then((res) => {
          setHistoryOrders(res.data.data);
        })
        .catch((err) => {
          setSnackbar({
            ...snackbar,
            open: true,
            message: 'Internal Server Error',
            type: 'error',
          });
        });
    }
  }, [filterByDate]);

  useEffect(() => {
    getActiveOrders();
  }, [getActiveOrders]);

  return (
    <Container
      sx={{
        pt: {
          xs: 2,
          md: 4,
        },
        pb: 10,
      }}>
      <Box
        sx={{
          mb: 3,
        }}>
        <Filters filterByDate={filterByDate} setFilterByDate={setFilterByDate} />
      </Box>

      {!historyOrders?.length > 0 && (
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: 'grey.500',
            }}>
            You don't have any history booking
          </Typography>
        </Box>
      )}

      {historyOrders?.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: {
              xs: 2,
              md: 3,
            },
          }}>
          {historyOrders?.map((order, i) => {
            return <BookingCard key={i} order={order} />;
          })}
        </Box>
      )}
    </Container>
  );
};

export default HistoryBooking;
