import { HelmetProvider } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import Pages from './pages';
import { AppProvider } from './context/appContext';
import './app.scss';
import './slick.css';
import FloatingWhatsApp from './components/floatingWhatsappIcon';

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <Pages />
          <FloatingWhatsApp />
        </AppProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
