import { TimelineDot } from '@mui/lab';
import { Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import React from 'react';

const Number = (props) => {
  const { i, filled } = props;

  if (filled) {
    return (
      <TimelineDot
        sx={{
          bgcolor: 'black',
          color: 'white',
          height: '1rem',
          width: '1rem',
          boxShadow: 'none',
          m: 0,
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '100%',
          }}>
          <Typography variant="h6" component="span">
            {i}
          </Typography>
        </Box>
      </TimelineDot>
    );
  }

  return (
    <TimelineDot
      sx={{
        bgcolor: 'white',
        color: grey[700],
        height: '1rem',
        width: '1rem',
        boxShadow: 'none',
        border: '2px solid' + grey[700],
        m: 0,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
        }}>
        <Typography variant="h6" component="span">
          {i}
        </Typography>
      </Box>
    </TimelineDot>
  );
};

export default Number;
