import React from 'react';
import { Box, Card, CardActionArea, CardContent, CardMedia, Link, Typography } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { grey } from '@mui/material/colors';
import styles from './styles.module.scss';
import { PATH } from '../../../config/path';

const TourCard = (props) => {
  const { title, slug, city_code, duration, new_price, old_price, product_image } = props.tour;

  return (
    <Box>
      <Link href={`${PATH.PRODUCTS}/${slug}`} underline="none">
        <Card
          variant="landing"
          sx={{
            borderRadius: 3,
            '&:hover': {
              backgroundColor: grey[50],
            },
          }}>
          <CardActionArea>
            <Box
              sx={{
                p: 1,
                position: 'relative',
              }}>
              <CardMedia
                className={styles.cardMedia}
                component={'img'}
                image={product_image}
                alt="Tour Image"
                loading="lazy"
                sx={{
                  borderRadius: 3,
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  px: 2,
                  pb: 3,
                  color: 'white',
                }}>
                <LocationOnOutlinedIcon />
                <Typography variant="body2" fontWeight={'600'}>
                  {city_code} | {duration}
                </Typography>
              </Box>
            </Box>
            <CardContent
              sx={{
                pt: 1,
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 0.5,
                }}>
                <Typography variant="body1" fontWeight={500}>
                  {title}
                </Typography>
                <Typography variant="body2" color={grey[500]} styles={'line-through'}>
                  ${old_price}
                </Typography>
                <Typography variant="body1" fontWeight={550}>
                  ${new_price}
                </Typography>
              </Box>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Box>
  );
};

export default TourCard;
