const blue = {
  50: '#2B1C4E',
  100: '#292460',
  200: '#2C3572',
  300: '#355084',
  400: '#3E6E95',
  500: '#5790A4',
  600: '#3a7f85',
  700: '#89C2BC',
  800: '#A2D0C3',
  900: '#BCDECE',
};

export default blue;
