import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  TextField,
  Button,
  Modal,
  Avatar,
} from '@mui/material';
import ReactHtmlParser from 'html-react-parser';
import { useFormik } from 'formik';
import commentSchema from '../../modules/validation-schema/CommentSchema';
import { LoadingButton } from '@mui/lab';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useApp } from '../../context/appContext';
import CommentCard from './components/CommentCard';
import client from '../../modules/axios';
import { API, PATH } from '../../config/path';
import dayjs from 'dayjs';
import { getAuthCookie } from '../../context/auth';
import { Helmet } from 'react-helmet-async';
import ShareSosmed from './components/ShareSosmed';
import { useTheme } from '@mui/material/styles';

const BlogDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const theme = useTheme();

  const { snackbar, setSnackbar } = useApp();

  const [blog, setBlog] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [comments, setComments] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isDisabledEdit, setIsDisabledEdit] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [fetchComment, setFetchComment] = useState(false);
  const [comment, setComment] = useState({
    id: null,
    comment: '',
  });
  const [postComment, setPostComment] = useState('');

  const getBlog = useCallback(async () => {
    try {
      const res = await client.get(`${API.BLOGS}/detail/${slug}`);

      const data = res.data.data[0];
      data.date = dayjs(data.created_at).format('MMMM DD, YYYY');

      setBlog(data);
      setFetchComment(true);
    } catch {
      setSnackbar({
        ...snackbar,
        open: true,
        message: 'Internal Server Error',
        type: 'error',
      });
    }
  }, [slug]);

  const getComments = useCallback(async () => {
    if (!fetchComment) return;

    try {
      const res = await client.get(`${API.GET_COMMENT}/${blog.id}`);

      const data = res.data.data.map((v) => {
        return {
          ...v,
          id_user: parseInt(v.id_user),
          id_blog: parseInt(v.id_blog),
        };
      });

      setComments(data);
    } catch {
      setSnackbar({
        ...snackbar,
        open: true,
        message: 'Internal Server Error',
        type: 'error',
      });
    }

    setFetchComment(false);
  }, [fetchComment]);

  useEffect(() => {
    if (searchParams.has('from') && searchParams.get('from') === 'login') {
      setPostComment(localStorage.getItem('postComment'));

      const element = document.getElementById('add-comment');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [searchParams]);

  useEffect(() => {
    getBlog();
  }, [getBlog]);

  useEffect(() => {
    getComments();
  }, [getComments]);

  const handleClose = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      comment: postComment || '',
    },
    enableReinitialize: true,
    validationSchema: commentSchema,
    onSubmit: async (values) => {
      setIsDisabled(true);

      if (!getAuthCookie()) {
        localStorage.setItem('postComment', values.comment);

        navigate({
          pathname: PATH.LOGIN,
          search: `?${createSearchParams({
            from: encodeURIComponent(window.location.href),
          })}`,
        });

        return;
      }

      try {
        await client.post(
          API.CREATE_COMMENT,
          {
            id_blog: blog.id,
            comment: values.comment,
          },
          {
            headers: {
              Authorization: 'Bearer ' + getAuthCookie(),
            },
          },
        );

        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Successfully added comment',
          type: 'success',
        });

        formik.resetForm();
        localStorage.removeItem('postComment');
        setPostComment('');
        setFetchComment(true);
      } catch {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Internal server error',
          type: 'error',
        });
      } finally {
        setIsDisabled(false);
      }
    },
  });

  const handleEditComment = async (curComment) => {
    // make sure the comment is not an empty string
    if (curComment.comment === '') return;

    setIsLoadingEdit(true);

    try {
      await client.post(
        `${API.EDIT_COMMENT}/${curComment.id}`,
        {
          id_blog: blog.id,
          comment: comment.comment,
        },
        {
          headers: {
            Authorization: 'Bearer ' + getAuthCookie(),
          },
        },
      );

      setSnackbar({
        ...snackbar,
        open: true,
        message: 'Successfully updated comment',
        type: 'success',
      });

      handleClose();
      setFetchComment(true);
    } catch {
      setSnackbar({
        ...snackbar,
        open: true,
        message: 'Internal server error',
        type: 'error',
      });
    } finally {
      setIsDisabled(false);
      setIsLoadingEdit(false);
    }
  };

  const handleDeleteComment = async (curComment) => {
    try {
      await client.delete(`${API.DELETE_COMMENT}/${curComment.id}`, {
        headers: {
          Authorization: 'Bearer ' + getAuthCookie(),
        },
      });

      setSnackbar({
        ...snackbar,
        open: true,
        message: 'Successfully deleted comment',
        type: 'success',
      });

      setFetchComment(true);
    } catch {
      setSnackbar({
        ...snackbar,
        open: true,
        message: 'Internal server error',
        type: 'error',
      });
    }
  };

  // console.log('current comment', comment);
  // console.log('slug', slug);
  // console.log('blog', blog);
  // console.log('postComment', postComment);

  return (
    <>
      <Helmet>
        {blog?.meta_tag.map((meta, i) => {
          return <meta key={i} {...{ [meta.tag_type]: meta.value }} content={meta.content} />;
        })}
      </Helmet>

      <Container
        maxWidth={'md'}
        sx={{
          pt: 6,
          mb: {
            xs: 4,
            md: 6,
          },
          // bgcolor: '#eeeeee'
        }}>
        {/* HEADER */}
        <Box>
          <Typography
            variant="h1"
            sx={{
              fontWeight: 500,
              textAlign: 'center',
              fontSize: {
                xs: '1.5rem',
                md: '2rem',
              },
              lineHeight: {
                xs: '2rem',
                md: '2.8rem',
              },
              mb: {
                xs: 3,
              },
              px: {
                md: 12,
              },
            }}>
            {blog?.title}
          </Typography>

          {blog && (
            <Typography
              variant="body1"
              sx={{
                textAlign: 'center',
                fontSize: {
                  xs: '0.75rem',
                  md: '.85rem',
                },
              }}>
              {blog?.date.toUpperCase()} / {blog?.category.toUpperCase()}
            </Typography>
          )}
        </Box>
        {/* END OF HEADER */}
      </Container>

      <Container
        sx={{
          px: {
            md: 12,
          },
          // bgcolor: '#eeeeee'
        }}>
        {/* MAIN IMAGE */}
        <Box>
          <Box
            component="img"
            sx={{
              height: {
                xs: 200,
                sm: 400,
                md: 505,
              },
              width: '100%',
              objectFit: 'cover',
              maxWidth: '100%',
              bgcolor: '#eeeeee',
            }}
            alt="thumbnail"
            src={blog?.thumbnail}
            loading="lazy"
          />
        </Box>
        {/* END OF MAIN IMAGE */}
      </Container>

      <Container
        maxWidth={'md'}
        sx={{
          // pt: 4,
          mb: {
            xs: 10,
            md: 16,
          },
          // bgcolor: '#eeeeee'
        }}>
        {/* SHARE SOSMED */}
        <Box
          sx={{
            // bgcolor: 'yellow',
            my: {
              xs: 3,
              md: 6,
            },
          }}>
          <ShareSosmed title={blog?.title} />
        </Box>
        {/* END OF SHARE SOSMED */}

        {/* CONTENT */}
        <Box className="blog-detail-content">{blog && ReactHtmlParser(blog.content + '')}</Box>
        {/* END OF CONTENT */}

        {/* SHARE SOSMED */}
        <Box
          sx={{
            // bgcolor: 'yellow',
            my: {
              xs: 3,
              md: 6,
            },
          }}>
          <ShareSosmed title={blog?.title} />
        </Box>
        {/* END OF SHARE SOSMED */}

        {/* PREVIOUS POST */}
        {blog && blog.previous_post && (
          <Box
            sx={
              {
                // bgcolor: 'grey',
              }
            }>
            <Grid container spacing={0}>
              <Grid
                item
                md={6}
                sx={{
                  // bgcolor: 'yellow',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Link
                  href={`${PATH.BLOGS}/${blog?.previous_post.slug}`}
                  underline="none"
                  color={'inherit'}
                  sx={{
                    // bgcolor: 'orange',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    gap: {
                      xs: 2,
                      md: 3,
                    },
                    mr: {
                      xs: 2,
                      md: 8,
                    },
                    ':hover': {
                      '.MuiTypography-h3': {
                        color: theme.palette.primary.main,
                      },
                    },
                  }}>
                  <Box>
                    <Avatar
                      alt="thumbnail"
                      src={blog?.previous_post.thumbnail}
                      sx={{
                        height: {
                          xs: '3.5rem',
                          md: '4.5rem',
                        },
                        width: {
                          xs: '3.5rem',
                          md: '4.5rem',
                        },
                        bgcolor: '#eeeeee',
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: 500,
                        fontSize: {
                          xs: '.65rem',
                          md: '.75rem',
                        },
                        color: 'text.secondary',
                        mb: 1,
                      }}>
                      PREVIOUS POST
                    </Typography>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: 500,
                        fontSize: {
                          xs: '.75rem',
                          md: '.9rem',
                        },
                        lineHeight: {
                          xs: '1rem',
                          md: '1.35rem',
                        },
                      }}>
                      {blog?.previous_post.title}
                    </Typography>
                  </Box>
                </Link>

                <Box
                  sx={{
                    width: '2px',
                    height: '45px',
                    bgcolor: '#eeeeee',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        {/* END OF PREVIOUS POST */}

        {/* REPLY */}
        <Box
          sx={{
            mt: {
              xs: 10,
              md: 16,
            },
          }}
          id="add-comment">
          <form onSubmit={formik.handleSubmit}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 500,
                fontSize: {
                  xs: '1rem',
                  md: '1.5rem',
                },
                mb: 2,
              }}>
              Leave a Reply
            </Typography>
            <TextField
              fullWidth
              name="comment"
              placeholder="Add Comment"
              label="Add Comment"
              required={true}
              variant="outlined"
              type="text"
              multiline
              rows={8}
              value={formik.values.comment}
              onChange={formik.handleChange}
              error={formik.touched.comment && Boolean(formik.errors.comment)}
              helperText={formik.touched.comment && formik.errors.comment}
              sx={{
                '& .MuiInputBase-inputMultiline': {
                  p: 0,
                },
                // bgcolor: 'white'
              }}
            />
            <Box
              sx={{
                pt: 3,
              }}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={isDisabled}
                loading={isDisabled}
                loadingIndicator="Loading…"
                sx={{
                  px: 2,
                }}>
                Post Comment
              </LoadingButton>
            </Box>
          </form>
        </Box>
        {/* END OF REPLY */}

        {/* COMMENT LIST */}
        {comments.length > 0 && (
          <Box
            sx={{
              pt: {
                xs: 5,
                md: 5,
              },
            }}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: 500,
                fontSize: {
                  xs: '1rem',
                  md: '1.5rem',
                },
                mb: {
                  xs: 3,
                  md: 3,
                },
              }}>
              Comments
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: {
                  xs: 2,
                  md: 2,
                },
              }}>
              {comments.map((comment, i) => {
                return (
                  <CommentCard
                    key={i}
                    comment={comment}
                    setComment={setComment}
                    setOpen={setOpen}
                    handleDeleteComment={handleDeleteComment}
                  />
                );
              })}
            </Box>
          </Box>
        )}
        {/* END OF COMMENT LIST */}

        {/* MODAL */}
        <Modal open={isOpen} onClose={handleClose}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}>
            <Box
              sx={{
                backgroundColor: '#fff',
                borderRadius: 2,
                p: 4,
                width: '100%',
                maxWidth: 700,
                m: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}>
              <Typography variant="h3">Edit Comment</Typography>

              <TextField
                fullWidth
                name="comment-edit"
                variant="outlined"
                type="text"
                multiline
                rows={8}
                value={comment.comment}
                onChange={(e) => {
                  setComment({
                    ...comment,
                    comment: e.target.value,
                  });

                  if (e.target.value === '') setIsDisabledEdit(true);
                  else setIsDisabledEdit(false);
                }}
                sx={{
                  '& .MuiInputBase-inputMultiline': {
                    p: 0,
                  },
                  // bgcolor: 'white'
                }}
              />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: 1,
                }}>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isDisabledEdit}
                  loading={isLoadingEdit}
                  loadingIndicator="Loading…"
                  onClick={() => {
                    handleEditComment(comment);
                  }}
                  sx={{
                    px: 4,
                  }}>
                  Save
                </LoadingButton>
              </Box>
            </Box>
          </Box>
        </Modal>
        {/* END OF MODAL */}
      </Container>
    </>
  );
};

export default BlogDetail;
