import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useApp } from '../context/appContext';
import { useFormik } from 'formik';
import resetPasswordSchema from '../modules/validation-schema/resetPasswordSchema';
import { Box, Button, Container, Grid, Link, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { API, PATH } from '../config/path';
import client from '../modules/axios';
import { Link as ReactRouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ResetPassword = () => {
  const { token } = useParams();
  const { snackbar, setSnackbar } = useApp();
  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      token: token,
      new_password: '',
      password_confirm: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      client
        .post(API.RESET_PASSWORD, values)
        .then((res) => {
          if (res.data.error) {
            setSnackbar({
              ...snackbar,
              open: true,
              message: res.data.message,
              type: 'error',
            });
            return;
          }

          setSnackbar({
            ...snackbar,
            open: true,
            message: res.data.success,
            type: 'success',
          });

          navigate(PATH.LOGIN);
        })
        .catch((err) => {
          setSnackbar({
            ...snackbar,
            open: true,
            message: 'Something went wrong',
            type: 'error',
          });

          setIsDisabled(false);
        });
    },
  });

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 3,
          border: '1px solid' + grey[300],
          borderRadius: 1,
        }}>
        <Box
          sx={{
            backgroundColor: grey[100],
            py: 1.5,
            px: 2.5,
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}>
            <Link
              component={ReactRouterLink}
              to={PATH.HOME}
              color={'primary'}
              underline="hover"
              align="right">
              <Button
                variant="text"
                color="primary"
                startIcon={<ArrowBackIcon />}
                sx={{
                  py: 0,
                }}>
                Home
              </Button>
            </Link>
            <Typography variant="body1">Reset Password</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            py: 2,
            px: 2.5,
          }}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">Password</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="new_password"
                  type="password"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={formik.touched.new_password && Boolean(formik.errors.new_password)}
                  helperText={formik.touched.new_password && formik.errors.new_password}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={4}>
                <Typography variant="body1">Confirm Password</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="password_confirm"
                  type="password"
                  value={formik.values.password_confirm}
                  onChange={formik.handleChange}
                  variant="outlined"
                  fullWidth
                  error={formik.touched.password_confirm && Boolean(formik.errors.password_confirm)}
                  helperText={formik.touched.password_confirm && formik.errors.password_confirm}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={8}>
                <Box
                  sx={{
                    pt: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    flexDirection: {
                      xs: 'column',
                      sm: 'row',
                    },
                  }}>
                  <Button type="submit" variant="contained" disabled={isDisabled}>
                    Reset Password
                  </Button>
                  <Link
                    component={ReactRouterLink}
                    to={PATH.LOGIN}
                    color={'primary'}
                    underline="hover">
                    <Typography variant="body1">Login</Typography>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Container>
  );
};

export default ResetPassword;
