import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import blue from '../../../../theme/colors/blue';
import TopDestinationCard from '../TopDestinationCard';

const TopDestination = (props) => {
  const { data } = props;

  return (
    <Box>
      <Grid container columnSpacing={3} rowSpacing={3}>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
          }}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: {
                  xs: 'center',
                  sm: 'start',
                },
              }}>
              <Typography
                variant="h1"
                sx={{
                  borderBottom: '3px solid ' + blue[400],
                  pb: {
                    xs: 1,
                    md: 1,
                  },
                  px: {
                    xs: 2,
                    md: 0,
                  },
                  mb: {
                    xs: 1.5,
                    md: 2,
                  },
                  fontWeight: 500,
                  fontSize: {
                    xs: '1.25rem',
                    md: '1.65rem',
                  },
                }}>
                Top Destination
              </Typography>
            </Box>
            <Typography
              variant="body1"
              bold="light"
              sx={{
                textAlign: {
                  xs: 'center',
                  sm: 'left',
                },
              }}>
              Unveiling World's Most Desirable Locations
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md>
          <Box
            sx={{
              display: {
                xs: 'flex',
                md: 'grid',
              },
              gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
              flexWrap: 'nowrap',
              overflowX: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              gap: {
                xs: 1,
                sm: 3,
              },
            }}>
            {data &&
              data.map((top_destination, i) => {
                return (
                  <Box key={i}>
                    <TopDestinationCard top_destination={top_destination} />
                  </Box>
                );
              })}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TopDestination;
