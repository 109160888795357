import { Box, Container, Grid, Link, Typography } from '@mui/material';
import ContactUsForm from './components/ContactUsForm';

const ContactUs = () => {
  return (
    <Container
      sx={{
        pt: 4,
        mb: 10,
      }}>
      <Box mb={6}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            textAlign: 'center',
          }}>
          Contact Us
        </Typography>
      </Box>

      <Box>
        <Grid
          container
          rowSpacing={{
            xs: 4,
          }}>
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: {
                  xs: 2,
                  md: 3,
                },
              }}>
              <Typography variant="body1">
                Do not hesitate to get in touch if you have any questions.
              </Typography>
              <Typography variant="h3">INDOADDICT Office</Typography>

              <Box>
                <Typography variant="h4">CV. JOTU INDONESIA</Typography>
                <Typography variant="body1">Parangtritis Street km 16,7</Typography>
                <Typography variant="body1">Yogyakarta - Indonesia</Typography>
              </Box>

              <Box>
                <Typography variant="body1">tours@indoaddict.com</Typography>
                <Link href="https://wa.me/6282228751657" target="_blank" rel="noopener noreferrer">
                  +6282228751657
                </Link>
              </Box>

              <Typography variant="body1">
                You can also reach us using the available contact form. We will respond as soon as
                possible!
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box
              sx={{
                pl: {
                  xs: 0,
                  md: 4,
                  lg: 16,
                },
              }}>
              <ContactUsForm />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default ContactUs;
