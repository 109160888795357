import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Using Material-UI icon
import { Box } from '@mui/material';

const FloatingWhatsApp = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: '180px',
        right: '10px',
        zIndex: 1000,
      }}>
      <a
        href="https://api.whatsapp.com/send?phone=6282228751657&text=Hi%20Indoaddict%20Can%20i%20get%20package%20info"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          backgroundColor: '#25D366',
          color: 'white',
          textDecoration: 'none',
        }}>
        <WhatsAppIcon style={{ fontSize: '36px' }} />
      </a>
    </Box>
  );
};

export default FloatingWhatsApp;
