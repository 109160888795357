import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Pagination,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { API, PATH } from '../../config/path';
import { useSearchParams } from 'react-router-dom';
import ArticleCard from './components/ArticleCard';
import client from '../../modules/axios';
import { useApp } from '../../context/appContext';

const Blogs = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { snackbar, setSnackbar } = useApp();

  const [search, setSearch] = useState('');
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(5);
  const [refetch, setRefetch] = useState(false);

  const getBlogs = useCallback(async () => {
    if (!refetch) return;

    const titleParam = search !== '' ? `title=${search}` : '';

    try {
      const res = await client.get(`${API.BLOGS}?${titleParam}&page=${page}`);

      const data = res.data.data.data;

      setBlogs(data);
      setTotalPage(res.data.total);
    } catch {
      setSnackbar({
        ...snackbar,
        open: true,
        message: 'Internal Server Error',
        type: 'error',
      });
    }

    // set refetch value to false after fetching the data
    setRefetch(false);
  }, [page, search, refetch]);

  useEffect(() => {
    getBlogs();
  }, [getBlogs]);

  useEffect(() => {
    if (searchParams.has('search')) {
      setSearch(searchParams.get('search'));
    }

    if (searchParams.has('page')) {
      setPage(parseInt(searchParams.get('page')));
    }

    // make sure to fetch data when the search & page are already set up
    setRefetch(true);
  }, [searchParams]);

  const handleSearch = () => {
    if (search === '') {
      navigate({
        pathname: PATH.BLOGS,
      });
    }

    // set page to 1 as default
    navigate({
      pathname: PATH.BLOGS,
      search: `?${createSearchParams({
        search: search,
        page: 1,
      })}`,
    });
  };

  // console.log('search', search);
  // console.log('page', page);
  // console.log('searchParams', searchParams);

  return (
    <Container
      sx={{
        pt: 4,
        mb: 10,
        // bgcolor: '#eeeeee'
      }}>
      {/* SEARCH SECTION */}
      <Box pb={8}>
        <Typography
          variant="h1"
          sx={{
            fontWeight: 500,
            textAlign: 'center',
            fontSize: {
              xs: '1.75rem',
              md: '2.5rem',
            },
            mb: 1,
          }}>
          Find Your Interests
        </Typography>

        <Box
          sx={{
            // bgcolor: 'orange',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: {
                xs: 1,
              },
              py: 1,
              width: {
                xs: '100%',
                md: '35%',
              },
              // bgcolor: 'green'
            }}>
            <TextField
              placeholder="Search destinations, tours, activities"
              value={search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  console.log('search', search);
                  handleSearch();
                }
              }}
              variant="outlined"
              fullWidth
              sx={{
                backgroundColor: '#fff',
                borderRadius: 1.5,
                '& .MuiOutlinedInput-input': {
                  pl: 0,
                  fontSize: {
                    xs: '0.85rem',
                  },
                },
                height: {
                  xs: '2.5rem',
                },
              }}
            />

            <Button
              variant="contained"
              onClick={() => {
                console.log('search', search);
                handleSearch();
              }}
              sx={{
                height: {
                  xs: '2.5rem',
                },
                fontSize: {
                  xs: '0.85rem',
                },
                display: {
                  // xs: 'none',
                  sm: 'flex',
                },
              }}>
              Search
            </Button>
          </Box>
        </Box>
      </Box>
      {/* END OF SEARCH SECTION */}

      {/* ARTICLE LIST */}
      <Box>
        <Grid
          container
          spacing={{
            xs: 2,
            md: 6,
          }}
          sx={{
            // bgcolor: 'yellow',
            pb: 6,
          }}>
          {blogs.length > 0 &&
            blogs.map((article, i) => {
              return (
                <Grid key={i} item sm={1} md={4}>
                  <ArticleCard blog={article} />
                </Grid>
              );
            })}
        </Grid>
      </Box>
      {/* END OF ARTICLE LIST */}

      {/* PAGINATION */}
      <Box
        sx={
          {
            // bgcolor: 'yellow',
          }
        }>
        <Grid item xs>
          <Pagination
            sx={[{ '.MuiPagination-ul': { justifyContent: 'center' } }]}
            count={totalPage}
            page={page}
            onChange={(e) => {
              // handle previous button
              if (e.target.textContent === '' && e.target.dataset.testid === 'NavigateBeforeIcon') {
                return navigate({
                  pathname: PATH.BLOGS,
                  search: `?${createSearchParams({
                    search: search,
                    page: page - 1,
                  })}`,
                });
              }

              // handle next button
              if (e.target.textContent === '' && e.target.dataset.testid === 'NavigateNextIcon') {
                return navigate({
                  pathname: PATH.BLOGS,
                  search: `?${createSearchParams({
                    search: search,
                    page: page + 1,
                  })}`,
                });
              }

              // set the current page to the number clicked
              if (e.target.textContent !== '') {
                navigate({
                  pathname: PATH.BLOGS,
                  search: `?${createSearchParams({
                    search: search,
                    page: e.target.textContent,
                  })}`,
                });
              }
            }}
            size="large"
            shape="rounded"
          />
        </Grid>
      </Box>
      {/* END OF PAGINATION */}
    </Container>
  );
};

export default Blogs;
