import { Typography, Container, Box, useTheme, useMediaQuery } from '@mui/material';
import Hero from './components/Hero';
import { TourCard } from '../../components/Card';
import Badges from './components/Badges';
import CustomSlick from '../../components/CustomSlick';
import blue from '../../theme/colors/blue';
import TopDestination from './components/TopDestination';

const Heading = ({ title }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Typography
        variant="h1"
        sx={{
          borderBottom: '3px solid ' + blue[400],
          fontWeight: 500,
          fontSize: {
            xs: '1.25rem',
            md: '1.65rem',
          },
          pb: {
            xs: 1,
            md: 1,
          },
          mb: {
            xs: 3,
            md: 4,
          },
          px: 2,
        }}>
        {title}
      </Typography>
    </Box>
  );
};

const Index = ({ topRatedTours, topDestinations, recommendedTours, ...props }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Hero />
      <Box
        sx={{
          bgcolor: 'rgba(62, 110, 149, 0.05)',
          py: {
            xs: 4,
            md: 6,
          },
        }}>
        <Container
          sx={{
            px: {
              md: 12,
            },
          }}>
          <Heading title="INDOADDICT" />

          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              mb: {
                xs: 3,
              },
              fontSize: {
                md: '1.25rem',
              },
            }}>
            ⁠Indoaddict is a tour agent dedicated to providing unforgettable tours tailored to
            customer preferences. Our marketplace, indoaddict.com, offers a wide range of carefully
            curated Indonesia tours, from serene beach getaways to adventurous jungle treks. We work
            closely with local experts to ensure that every Indonesia trip reflects the true essence
            of the country's rich culture and breathtaking landscapes.
          </Typography>

          <Typography
            variant="body1"
            sx={{
              textAlign: 'center',
              fontSize: {
                md: '1.25rem',
              },
            }}>
            At indoaddict.com, customer satisfaction is our top priority. Our user-friendly platform
            makes it easy for you to find and book the perfect Indonesia travel experience that
            matches your interests and needs. Whether you're seeking relaxation, adventure, or
            cultural immersion, Indoaddict promises a seamless and personalized journey, creating
            memories that will last a lifetime.
          </Typography>
        </Container>
      </Box>

      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: {
              xs: 4,
            },
            pt: {
              xs: 3,
              md: 6,
            },
          }}>
          <Box
            sx={{
              mb: {
                sm: 4,
              },
            }}>
            <TopDestination data={topDestinations} />
          </Box>

          {/* Top reted tours */}
          <Box>
            <Heading title="Top Rated Tours" />

            {/* mobile view */}
            {mobileView && (
              <Box
                sx={{
                  display: {
                    xs: 'flex',
                  },
                  flexWrap: 'nowrap',
                  overflowX: 'auto',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  gap: 0,
                  mb: -6,
                }}>
                {topRatedTours.length > 0 &&
                  topRatedTours.map(function (tour, i) {
                    return (
                      <Box
                        key={i}
                        sx={{
                          minWidth: 220,
                        }}>
                        <TourCard tour={tour} />
                      </Box>
                    );
                  })}
              </Box>
            )}

            {!mobileView && (
              <Box>
                <CustomSlick slideToShow={4}>
                  {topRatedTours.length > 0 &&
                    topRatedTours.map(function (tour, i) {
                      return <TourCard tour={tour} key={i} />;
                    })}
                </CustomSlick>
              </Box>
            )}
          </Box>

          {/* Recommended tours */}
          <Box>
            <Heading title="Recommended Tours" />

            {/* mobile view */}
            {mobileView && (
              <Box
                sx={{
                  display: {
                    xs: 'flex',
                  },
                  flexWrap: 'nowrap',
                  overflowX: 'auto',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  gap: 0,
                  mb: -6,
                }}>
                {recommendedTours.length > 0 &&
                  recommendedTours.map(function (tour, i) {
                    return (
                      <Box
                        key={i}
                        sx={{
                          minWidth: 220,
                        }}>
                        <TourCard tour={tour} />
                      </Box>
                    );
                  })}
              </Box>
            )}

            {!mobileView && (
              <Box>
                <CustomSlick slideToShow={4}>
                  {recommendedTours.length > 0 &&
                    recommendedTours.map(function (tour, i) {
                      return <TourCard tour={tour} key={i} />;
                    })}
                </CustomSlick>
              </Box>
            )}
          </Box>

          {/* Our Partners */}
          <Box>
            <Heading title="Our Partners" />

            <Box
              sx={{
                mb: 10,
              }}>
              <Badges />
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Index;
