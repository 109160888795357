import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Hr from '../../../../components/Hr';

const Meeting = (props) => {
  const { product } = props;
  return (
    <Container>
      <Box
        sx={{
          my: 4,
        }}>
        <Typography variant="h2" mb={3}>
          Meeting and Pickup
        </Typography>

        <Box mb={4}>
          <Typography variant="h4" mb={2}>
            Pickup points
          </Typography>

          <Typography variant="body1">
            Pick up point will be available only on Airport or Hotel
          </Typography>
        </Box>

        <Box>
          <Typography variant="h4" mb={2}>
            Start time
          </Typography>

          <Typography variant="body1">{product.start_time}</Typography>
        </Box>
      </Box>
      <Hr />
    </Container>
  );
};

export default Meeting;
