import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useCallback, useEffect, useState } from 'react';
import { API } from '../../../config/path';
import { getAuthCookie } from '../../../context/auth';
import client from '../../../modules/axios';

const styles = StyleSheet.create({
  page: {
    padding: 36,
    paddingTop: 36,
    paddingBottom: 36,
    fontSize: '12px',
    fontFamily: 'Helvetica',
    lineHeight: '1.5px',
  },
  heading1: {
    fontSize: '16px',
    fontFamily: 'Helvetica-Bold',
  },
  heading2: {
    fontSize: '14px',
    fontFamily: 'Helvetica-Bold',
    color: '#87867e',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
});

const List = ({ children }) => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '6px',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingLeft: '10px',
        marginBottom: '10px',
      }}>
      <Text>{'\u2022 '}</Text>
      <Text>{children}</Text>
    </View>
  );
};

const ETicket = () => {
  const [ticket, setTicket] = useState(null);

  const getTicket = useCallback(() => {
    client
      .post(
        API.TICKET,
        {
          order_id: JSON.parse(localStorage.getItem('currOrderId')),
        },
        {
          headers: {
            Authorization: `Bearer ${getAuthCookie()}`,
          },
        },
      )
      .then((res) => {
        if (res.data.error) {
          return;
        }

        setTicket(res.data.message);
      });
  }, []);

  useEffect(() => {
    getTicket();
  }, [getTicket]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {ticket && (
          <>
            <View
              style={{
                display: 'flex',
                border: '1.5px solid #808080',
                flexDirection: 'row',
                marginBottom: '40px',
              }}>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  borderRight: '1.5px solid #808080',
                  gap: '6px',
                  padding: '10px',
                }}>
                <Image
                  src={'/assets/img/logo-indoaddict.jpg'}
                  style={{
                    height: '50px',
                    width: 'auto',
                    objectFit: 'contain',
                  }}
                />
                <Text style={styles.heading1}>{ticket.primary_traveler}</Text>
                <Text>{ticket.product_name}</Text>
                <Text style={styles.bold}>{ticket.booking_date}</Text>
                <Text style={styles.bold}>{ticket.total_traveler} pax</Text>
              </View>

              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '6px',
                  padding: '10px',
                }}>
                <Text
                  style={{
                    marginTop: '10px',
                    marginBottom: '20px',
                    ...styles.heading1,
                  }}>
                  Booking ref : {ticket.booking_ref}
                </Text>
                <Text>Booked by: {ticket.booked_by}</Text>
                <Text>{ticket.phone_number}</Text>
                <Text>{ticket.email}</Text>
              </View>
            </View>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                borderTop: '0.5px solid #ababab',
                paddingTop: '10px',
                marginBottom: '20px',
              }}>
              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  padding: '10px',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                  }}>
                  <Text style={styles.heading1}>Departure</Text>
                  <Text style={styles.heading2}>Departure Point</Text>
                  <Text>{ticket.departure_point}</Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                  }}>
                  <Text style={styles.heading2}>Departure Time</Text>
                  <Text>
                    Please refer to pick up time on the itinerary. We will reconfirm your pick up
                    time in advance. Please be patient in case of unforeseen delays along the pickup
                    route
                  </Text>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                  }}>
                  <Text style={styles.heading1}>Inclusions & Exclusions</Text>
                  <Text style={styles.heading2}>What's Included</Text>
                  <View>
                    {ticket.include_facility.map((item, i) => {
                      return <List key={i}>{item.name}</List>;
                    })}
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                  }}>
                  <Text style={styles.heading2}>What's Not Included</Text>
                  <View>
                    {ticket.exclude_facility.map((item, i) => {
                      return <List key={i}>{item.name}</List>;
                    })}
                  </View>
                </View>
              </View>

              <View
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  padding: '10px',
                }}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                  }}>
                  <Text style={styles.heading1}>Before You Go</Text>
                  <View>
                    <List>
                      You can present either a printed or a mobile voucher for this activity
                    </List>
                    <List>
                      This voucher is valid only on the date specified Free hotel pick up is
                      available for accommodations in the Yogyakarta city area
                    </List>
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                  }}>
                  <Text style={styles.heading1}>Before You Go</Text>
                  <Text style={styles.heading2}>{ticket.product_name}</Text>
                  <View>
                    {ticket.itinerary.map((item, i) => {
                      return (
                        <List key={i}>
                          <Text style={styles.bold}>{item.duration}</Text> - {item.activity} (Day{' '}
                          {item.day})
                        </List>
                      );
                    })}
                  </View>
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '6px',
                  }}>
                  <Text style={styles.heading1}>Special requirement</Text>
                  <Text>
                    {ticket.special_req ? ticket.special_req : 'No special requirement requested'}
                  </Text>
                </View>
              </View>
            </View>
            <View>
              <Text
                style={{
                  fontFamily: 'Helvetica-Bold',
                  textAlign: 'right',
                }}>
                Note : Stop can be made for your foods in between the trip.
              </Text>
            </View>
          </>
        )}
      </Page>
    </Document>
  );
};

export default ETicket;
