import React, { useState, useEffect } from 'react';
import { Grid, Button, Menu, MenuItem, IconButton, Typography } from '@mui/material';
import grey from '../../../../theme/colors/grey';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ControlPointOutlinedIcon from '@mui/icons-material/ControlPointOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';

const Availability = (props) => {
  const [travelerCheck, setTravelerCheck] = useState({
    adult: false,
    child: false,
    infant: false,
  });
  const [travelers, setTravelers] = useState({
    adult: 1,
    child: 0,
    infant: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (props.traveler) {
      setTravelerCheck({
        adult: props.traveler.adult,
        child: props.traveler.child,
        infant: props.traveler.infant,
      });
    }
  }, [props.traveler]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const increment = (type) => {
    switch (type) {
      case 'adult': {
        if (travelers.adult < 5) {
          setTravelers((prevState) => ({
            ...prevState,
            adult: prevState.adult + 1,
          }));
        }
        break;
      }
      case 'child': {
        if (travelers.child < 5 && travelerCheck.child) {
          setTravelers((prevState) => ({
            ...prevState,
            child: prevState.child + 1,
          }));
        }
        break;
      }
      case 'infant': {
        if (travelers.infant < 5 && travelerCheck.infant) {
          setTravelers((prevState) => ({
            ...prevState,
            infant: prevState.infant + 1,
          }));
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const decrement = (type) => {
    switch (type) {
      case 'adult': {
        if (travelers.adult > 1) {
          setTravelers((prevState) => ({
            ...prevState,
            adult: prevState.adult - 1,
          }));
        }
        break;
      }
      case 'child': {
        if (travelers.child > 0) {
          setTravelers((prevState) => ({
            ...prevState,
            child: prevState.child - 1,
          }));
        }
        break;
      }
      case 'infant': {
        if (travelers.infant > 0) {
          setTravelers((prevState) => ({
            ...prevState,
            infant: prevState.infant - 1,
          }));
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    props.onChange(travelers);
  }, [travelers]);

  return (
    <>
      <Button
        sx={{
          width: '100%',
          pl: '14px',
          py: 1,
          justifyContent: 'flex-start',
          backgroundColor: '#fff',
          borderColor: grey[300],
          color: '#000',
          pl: 2.25,
        }}
        variant="outlined"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        startIcon={<PersonOutlineOutlinedIcon sx={{ fontSize: '1.5rem !important' }} />}
        onClick={handleClick}>
        {travelers.adult > 0 ? travelers.adult + ' Adult' : ''}
        {travelers.child > 0
          ? travelers.adult > 0
            ? ', ' + travelers.child + ' Child'
            : travelers.child + ' Child'
          : ''}
        {travelers.infant > 0
          ? travelers.child > 0
            ? ', ' + travelers.infant + ' Infant'
            : travelers.infant + ' Infant'
          : ''}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            width: '300px',
          },
        }}>
        <MenuItem>
          <Grid container alignItems="center">
            <Grid item xs={7}>
              Adult
            </Grid>
            <Grid item container xs={5} justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => decrement('adult')}>
                  <RemoveCircleOutlineOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <Typography align="center">{travelers.adult}</Typography>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => increment('adult')}>
                  <ControlPointOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem>
          <Grid container alignItems="center">
            <Grid item xs={7}>
              Child
            </Grid>
            <Grid item container xs={5} justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => decrement('child')}>
                  <RemoveCircleOutlineOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <Typography align="center">{travelers.child}</Typography>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => increment('child')}>
                  <ControlPointOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem>
          <Grid container alignItems="center">
            <Grid item xs={7}>
              Infant
            </Grid>
            <Grid item container xs={5} justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => decrement('infant')}>
                  <RemoveCircleOutlineOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <Typography align="center">{travelers.infant}</Typography>
              </Grid>
              <Grid item xs={4}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  onClick={() => increment('infant')}>
                  <ControlPointOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </>
  );
};

export default Availability;
