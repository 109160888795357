import React, { useCallback, useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import Hr from '../../../../components/Hr';
import client from '../../../../modules/axios';
import ReactHtmlParser from 'html-react-parser';
import LearnMoreCancellationButton from '../LearnMoreCancellationButton';

const Cancellation = () => {
  const [cancellation, setCancellation] = useState(null);

  const getConcellation = useCallback(() => {
    return client
      .get('api/cancelation-policy')
      .then((res) => {
        setCancellation(res.data.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    getConcellation();
  }, [getConcellation]);

  return (
    <Container>
      <Box
        sx={{
          my: 4,
        }}>
        <Typography variant="h2" mb={3}>
          Cancellation Policy
        </Typography>

        <Box mb={3}>{cancellation && ReactHtmlParser(cancellation.content)}</Box>

        <Typography variant="body1">
          <LearnMoreCancellationButton /> about cancellations.
        </Typography>
      </Box>
      <Hr />
    </Container>
  );
};

export default Cancellation;
