import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import { green } from '@mui/material/colors';

const Overview = () => {
  return (
    <Box>
      <Typography variant="h3" mb={3} fontWeight={600}>
        Book with confidence
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}>
          <Box>
            <svg
              width="41"
              height="32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon__ci11">
              <path
                d="M37.528 3.523c7.33 7.508 1.178 8.818-5.401 15.242-6.579 6.423-11.171 10.177-18.422 8.018-7.25-2.158-18.268-12.047-11.69-18.47 6.579-6.424 28.182-12.299 35.513-4.79z"
                fill="#00B67A"></path>
              <path
                d="M20 2.427l2.879 8.86.168.519h9.862l-7.537 5.476-.441.32.168.518 2.879 8.86-7.537-5.475-.441-.32-.44.32-7.538 5.476 2.879-8.86.168-.519-.44-.32-7.538-5.476h9.862l.168-.518L20 2.426z"
                fill="#fff"
                stroke="#2A2D32"
                strokeWidth="1.5"></path>
            </svg>
          </Box>
          <Box>
            <Typography variant="h4" mb={1} fontWeight={600}>
              Excellent Trustpilot score
            </Typography>
            <Typography variant="body1">
              With more than 77,000 traveler reviews, you can get the full picture before you go.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}>
          <Box>
            <svg
              width="41"
              height="28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon__ci11">
              <path
                d="M37.528 3.523c7.33 7.508 1.178 8.818-5.401 15.242-6.579 6.423-11.171 10.177-18.422 8.018-7.25-2.158-18.268-12.047-11.69-18.47 6.579-6.424 28.182-12.299 35.513-4.79z"
                fill="#F6ECBB"></path>
              <mask id="svg-53230343a" fill="#fff">
                <rect x="5.869" y="4.224" width="28" height="17" rx="1"></rect>
              </mask>
              <rect
                x="5.869"
                y="4.224"
                width="28"
                height="17"
                rx="1"
                fill="#fff"
                stroke="#2A2D32"
                strokeWidth="3.4"
                mask="url(#svg-53230343a)"></rect>
              <path
                d="M22.39 17.956h3.285c.705 0 1.17-.491 1.17-1.123 0-.73.2-.911 1.13-.911.647 0 1.176-.505 1.176-1.208v-3.649c0-.703-.529-1.208-1.176-1.208-.93 0-1.13-.182-1.13-.911 0-.632-.465-1.123-1.17-1.123H22.33a.47.47 0 100 .94h3.346c.172 0 .228.06.228.183 0 1.271.641 1.851 2.071 1.851.137 0 .235.094.235.268v3.649c0 .173-.098.267-.235.267-1.43 0-2.072.58-2.072 1.852 0 .123-.055.182-.227.182H22.39a.47.47 0 100 .94zM16.392 7.823h-3.286c-.704 0-1.168.49-1.168 1.122 0 .73-.201.911-1.132.911-.646 0-1.175.506-1.175 1.209v3.648c0 .703.528 1.208 1.175 1.208.931 0 1.132.182 1.132.911 0 .632.464 1.123 1.168 1.123h3.345a.47.47 0 100-.94h-3.345c-.172 0-.228-.06-.228-.183 0-1.27-.641-1.851-2.072-1.851-.136 0-.234-.094-.234-.268v-3.648c0-.174.098-.268.234-.268 1.43 0 2.072-.58 2.072-1.852 0-.123.056-.182.228-.182h3.286a.47.47 0 000-.94z"
                fill="#2A2D32"
                stroke="#2A2D32"
                strokeWidth=".7"></path>
              <path
                d="M22.119 12.99c0 1.892-1.322 3.25-2.75 3.25s-2.75-1.358-2.75-3.25 1.322-3.25 2.75-3.25 2.75 1.358 2.75 3.25z"
                fill="#fff"
                stroke="#2A2D32"
                strokeWidth="1.5"></path>
            </svg>
          </Box>
          <Box>
            <Typography variant="h4" mb={1} fontWeight={600}>
              Exceptional flexibility
            </Typography>
            <Typography variant="body1">
              You're in control with free cancellation and lowest price guarantee to satisfy any
              plan or budget.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}>
          <Box>
            <svg
              width="42"
              height="30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="icon__ci11">
              <path
                d="M37.813 3.75C45.2 11.74 39 13.133 32.371 19.97c-6.628 6.837-11.256 10.832-18.562 8.535C6.503 26.207-4.598 15.683 2.031 8.846 8.659 2.01 30.426-4.242 37.813 3.749z"
                fill="#F5B8C3"></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.07 11.407V4.198H11.634V11.407h.113c.652 2.922 4.032 5.149 8.104 5.149 4.072 0 7.452-2.227 8.104-5.15h.113zm-8.218 11.706s2.054.515 5.136.515c0-2.275-2.3-4.12-5.136-4.12-2.836 0-5.136 1.845-5.136 4.12 3.082 0 5.136-.515 5.136-.515z"
                fill="#fff"></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.268 24.468a.666.666 0 01-.626-.887 6.621 6.621 0 014.047-4.058 6.647 6.647 0 011.549-.349v-1.387a9.274 9.274 0 01-8.627-9.293v-.535a.68.68 0 01-.023 0H7.246c-.165 1.287-.079 2.442.292 3.328.405.966 1.162 1.657 2.473 1.873a.668.668 0 01-.216 1.317c-1.77-.29-2.908-1.29-3.488-2.674-.562-1.34-.583-2.99-.29-4.63a.668.668 0 01.657-.55h3.914a.68.68 0 01.023 0V5.167c0-1.102.893-1.997 1.991-1.997H27.21c1.098 0 1.991.895 1.991 1.997v.77h3.829c.323 0 .6.233.657.551.292 1.638.272 3.289-.29 4.629-.58 1.385-1.718 2.384-3.488 2.674a.668.668 0 11-.217-1.317c1.311-.215 2.069-.907 2.473-1.873.371-.886.458-2.041.292-3.328H29.2v1.222a9.272 9.272 0 01-8.634 9.293v1.386a6.6 6.6 0 012.198.62 6.604 6.604 0 013.398 3.789.666.666 0 01-.626.886H14.268zM12.602 4.5H27.21c.366 0 .663.298.663.666v3.328c0 4.404-3.573 7.987-7.966 7.987-4.394 0-7.967-3.583-7.967-7.987V5.166c0-.368.297-.666.663-.666zm11.903 18.637a5.236 5.236 0 00-2.314-2.143 5.249 5.249 0 00-2.236-.523h-.004a.482.482 0 01-.092.001 5.308 5.308 0 00-1.728.306 5.26 5.26 0 00-2.831 2.36h9.205z"
                fill="#2A2D32"></path>
            </svg>
          </Box>
          <Box>
            <Typography variant="h4" mb={1} fontWeight={600}>
              24/7 global support
            </Typography>
            <Typography variant="body1">
              Our award winning support team is here to help, 24/7.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                py: 2,
              }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                }}>
                <PhoneOutlinedIcon
                  sx={{
                    color: green[800],
                    fontSize: '18px',
                  }}
                />
                <Typography variant="body1">+6282228751657</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                }}>
                <ChatOutlinedIcon
                  sx={{
                    color: green[800],
                    fontSize: '18px',
                  }}
                />
                <Typography variant="body1">
                  <Link href="https://wa.me/6282228751657" color="inherit">
                    Chat now
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Overview;
