const grey = {
  50: '#FFFFFF',
  100: '#FFFFFF',
  200: '#E6E6E6',
  300: '#CCCCCC',
  400: '#B3B3B3',
  500: '#999999',
  600: '#808080',
  700: '#666666',
  800: '#4D4D4D',
  900: '#000000',
};

export default grey;
