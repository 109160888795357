import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Hr from '../../../../components/Hr';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const Item = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 2,
        mb: 2,
      }}>
      {props.icon ? props.icon : <CheckIcon />}
      <Typography variant="body1">{props.children}</Typography>
    </Box>
  );
};

const Included = (props) => {
  const { product } = props;

  let includes = product.include_facility
    ? product.include_facility.map(function (data) {
        return data.name;
      })
    : [];

  let excludes = product.exclude_facility
    ? product.exclude_facility.map(function (data) {
        return data.name;
      })
    : [];

  return (
    <Container>
      <Box
        sx={{
          my: 4,
        }}>
        <Typography variant="h2">What's Included</Typography>

        <Grid
          container
          spacing={2}
          sx={{
            mt: 0,
          }}>
          <Grid item xs={12} md={6}>
            {includes.length > 0 &&
              includes.map(function (data, i) {
                return <Item key={i}>{data}</Item>;
              })}
          </Grid>
          <Grid item xs={12} md={6}>
            {excludes.length > 0 &&
              excludes.map(function (data, i) {
                return (
                  <Item key={i} icon={<CloseIcon />}>
                    {data}
                  </Item>
                );
              })}
          </Grid>
        </Grid>
      </Box>
      <Hr />
    </Container>
  );
};

export default Included;
