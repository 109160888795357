import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import ReactHtmlParser from 'html-react-parser';
import Hr from '../../../../components/Hr';

const Overview = (props) => {
  const { product } = props;

  return (
    <Container>
      <Box
        sx={{
          my: 4,
        }}>
        <Typography variant="h2">Overview</Typography>

        <Box>{product && ReactHtmlParser(product.overview + '')}</Box>
      </Box>
      <Hr />
    </Container>
  );
};

export default Overview;
