import * as yup from 'yup';

const commentSchema = yup.object().shape({
  comment: yup
    .string()
    .required('Comment is required')
    .max(255, 'Comment must be at most 255 characters'),
});

export default commentSchema;
