import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const IconCard = (props) => {
  const { src, href, bgcolor, size } = props.sosmed;

  return (
    <Link
      href={href}
      underline="none"
      target="_blank"
      sx={{
        bgcolor: bgcolor,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '35px',
        width: '65px',
        borderRadius: 0.85,
        ':hover': {
          opacity: 0.8,
        },
      }}>
      <Box
        component="img"
        sx={{
          height: size,
          width: size,
          objectFit: 'contain',
          // bgcolor: 'yellow',
        }}
        alt="thumbnail"
        src={src}
        loading="lazy"
      />
    </Link>
  );
};

const ShareSosmed = (props) => {
  const { title } = props;

  const fb = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&display=popup&ref=plugin&src=like&kid_directed_site=0`;

  const x = `https://twitter.com/intent/tweet?original_referer=${encodeURIComponent(window.location.href)}&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&text=${encodeURIComponent(title)}&url=${encodeURIComponent(window.location.href)}`;

  const tele = `https://telegram.me/share/url?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(title)}`;

  const wa = `https://api.whatsapp.com/send?text=${encodeURIComponent(title)}%20${encodeURIComponent(window.location.href)}`;

  const reddit = `https://reddit.com/submit?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(title)}`;

  const flipboard = `https://share.flipboard.com/bookmarklet/popout?v=2&title=${encodeURIComponent(title)}&url=${encodeURIComponent(window.location.href)}`;

  const sosmed = [
    {
      src: require('../../../../images/facebook.png'),
      href: fb,
      bgcolor: '#557dbc',
      size: '.98rem',
    },
    {
      src: require('../../../../images/twitter.png'),
      href: x,
      bgcolor: 'black',
      size: '.75rem',
    },
    {
      src: require('../../../../images/reddit.png'),
      href: reddit,
      bgcolor: '#fc471e',
      size: '.85rem',
    },
    {
      src: require('../../../../images/telegram.png'),
      href: tele,
      bgcolor: '#329DDB',
      size: '.85rem',
    },
    {
      src: require('../../../../images/whatsapp.png'),
      href: wa,
      bgcolor: '#25d366',
      size: '.85rem',
    },
    {
      src: require('../../../../images/flipboard.png'),
      href: flipboard,
      bgcolor: '#c40812',
      size: '.85rem',
    },
  ];

  return (
    <>
      <Typography
        variant="body1"
        sx={{
          fontWeight: 500,
          fontSize: {
            xs: '0.75rem',
            md: '.9rem',
          },
          textAlign: 'center',
          mb: 2,
        }}>
        Share your love
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: {
            xs: 1.5,
            md: 1.85,
          },
          justifyContent: 'center',
        }}>
        {sosmed.map((v, i) => {
          return <IconCard key={i} sosmed={v} />;
        })}
      </Box>
    </>
  );
};

export default ShareSosmed;
