import { useCallback, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
} from 'react-router-dom';
import Layout from '../layout/Main';
import Login from './Login';
import Home from './Home';
import Destination from './Destination';
import Product from './Product';
import { getAuthCookie } from '../context/auth';
import { API, PATH } from '../config/path';
import Register from './Register';
import Checkout from './Checkout';
import { Alert, Slide, Snackbar } from '@mui/material';
import { useApp } from '../context/appContext';
import ActiveBooking from './ActiveBooking';
import HistoryBooking from './HistoryBooking';
import BookingDetails from './BookingDetails';
import HistoryBookingDetails from './HistoryBookingDetails';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import ContactUs from './ContactUs';
import AboutUs from './AboutUs';
import Blogs from './Blogs';
import BlogDetail from './BlogDetail';
import NotFound from './NotFound';
import client from '../modules/axios';

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // make sure there is no hash in the URL
    // if no hash in the URL, then scroll to the top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
};

const AuthRoute = () => {
  const token = getAuthCookie();
  return token ? <Navigate to={'/'} /> : <Outlet />;
};

const ProtectedRoute = () => {
  const token = getAuthCookie();
  return token ? <Outlet /> : <Navigate to={PATH.LOGIN} />;
};

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const App = () => {
  const { snackbar, setSnackbar, imgAsset, setImgAsset } = useApp();
  const { vertical, horizontal, open, message, type } = snackbar;

  const handleClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const fetchImageAsset = useCallback(async () => {
    if (imgAsset.length > 0) return;

    try {
      const res = await client.get(API.IMAGE_ASSET);

      const data = res.data.data;

      setImgAsset(data);
    } catch {}
  }, []);

  useEffect(() => {
    fetchImageAsset();
  }, [fetchImageAsset]);

  // console.log('imgAsset', imgAsset);

  return (
    <Router>
      <ScrollToTop />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
        TransitionComponent={TransitionDown}
        key={vertical + horizontal}>
        <Alert severity={type} variant="filled">
          {message}
        </Alert>
      </Snackbar>

      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path={PATH.DESTINATION} element={<Destination />} />
          <Route path={`${PATH.PRODUCTS}/:slug`} element={<Product />} />
          <Route path={PATH.CHECKOUT} element={<Checkout />} />
          <Route path={PATH.CONTACT_US} element={<ContactUs />} />
          <Route path={PATH.ABOUT_US} element={<AboutUs />} />
          <Route path={PATH.BLOGS} element={<Blogs />} />
          <Route path={`${PATH.BLOGS}/:slug`} element={<BlogDetail />} />
          <Route element={<ProtectedRoute />}>
            <Route path={PATH.ACTIVE_BOOKING} element={<ActiveBooking />} />
            <Route path={PATH.HISTORY_BOOKING} element={<HistoryBooking />} />
            <Route path={`${PATH.BOOKING_DETAILS}/:id`} element={<BookingDetails />} />
            <Route path={`${PATH.HISTORY_BOOKING}/:id`} element={<HistoryBookingDetails />} />
          </Route>
        </Route>
        <Route element={<AuthRoute />}>
          <Route path={PATH.LOGIN} element={<Login />} />
          <Route path={PATH.REGISTER} element={<Register />} />
        </Route>
        <Route element={<Outlet />}>
          <Route path={PATH.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={`${PATH.RESET_PASSWORD}/:token`} element={<ResetPassword />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
