import * as yup from 'yup';

const checkoutContactSchema = yup.object().shape({
  first_name_payer: yup.string().required('First name is required'),
  last_name_payer: yup.string().required('Last name is required'),
  email_payer: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  phone_number: yup.string().required('Phone number is required'),
  nationality: yup.string().required('Nationality is required'),
});

export default checkoutContactSchema;
