import { createTheme } from '@mui/material/styles';
import grey from './colors/grey';
import blue from './colors/blue';

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: blue[400],
    },
    secondary: {
      main: blue[600],
    },
  },
  typography: {
    allVariants: {
      fontFamily: 'Poppins, sans-serif',
    },
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontSize: '32px',
      // lineHeight: "40px",
      fontWeight: 600,
    },
    h2: {
      fontSize: '24px',
      // lineHeight: "32px",
      fontWeight: 550,
    },
    h3: {
      fontSize: '19px',
      // lineHeight: "24px",
      fontWeight: 500,
    },
    h4: {
      fontSize: '16px',
      // lineHeight: "20px",
      fontWeight: 400,
    },
    h5: {
      fontSize: '14px',
      // lineHeight: "18px",
    },
    h6: {
      fontSize: '13px',
      // lineHeight: "15px",
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 'normal',
    },
    body2: {
      fontSize: '0.8rem',
      fontWeight: 'normal',
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { bold: 'light' },
          style: {
            fontWeight: 400,
          },
        },
        {
          props: { bold: 'regular' },
          style: {
            fontWeight: 500,
          },
        },
        {
          props: { bold: 'medium' },
          style: {
            fontWeight: 600,
          },
        },
        {
          props: { bold: 'bold' },
          style: {
            fontWeight: 700,
          },
        },
        {
          props: { variant: 'p-large' },
          style: {
            fontFamily: 'Inter',
            fontSize: '18px',
            lineHeight: '27px',
            display: 'block',
          },
        },
        {
          props: { variant: 'p-reguler' },
          style: {
            fontFamily: 'Inter',
            fontSize: '16px',
            lineHeight: '24px',
            display: 'block',
          },
        },
        {
          props: { variant: 'p-small' },
          style: {
            fontFamily: 'Inter',
            fontSize: '14px',
            lineHeight: '21px',
            display: 'block',
          },
        },
        {
          props: { variant: 'p-xsmall' },
          style: {
            fontFamily: 'Inter',
            fontSize: '12px',
            lineHeight: '18px',
            display: 'block',
          },
        },
        {
          props: { styles: 'underline' },
          style: {
            textDecoration: 'underline',
          },
        },
        {
          props: { styles: 'italic' },
          style: {
            fontStyle: 'italic',
          },
        },
        {
          props: { styles: 'line-through' },
          style: {
            textDecorationLine: 'line-through',
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'capitalize',
          fontSize: '1rem',
          fontWeight: 400,
          borderRadius: 5,
          boxShadow: 'none',
          whiteSpace: 'nowrap',
          '&:hover': {
            boxShadow: 'none',
          },
          '&:disabled': {
            backgroundColor: grey[400],
            color: 'white',
            borderColor: grey[400],
          },
          '&.Mui-disabled': {
            backgroundColor: grey[400],
            color: 'white',
            borderColor: grey[400],
          },
          ...(ownerState.color === 'primary' && {
            ...(ownerState.variant === 'link' && {
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent',
                color: blue[600],
              },
            }),
            ...(ownerState.variant === 'outlined' && {
              ...(ownerState.selected === true && {
                backgroundColor: blue[400],
                color: '#fff',
              }),
            }),
          }),
          ...(ownerState.variant === 'outlined' && {
            borderWidth: 1.5,
            '&:hover': {
              borderWidth: 1.5,
              color: blue[400],
            },
            ...(ownerState.color === 'primary' && {
              ...(ownerState.selected === true && {
                '&:hover': {
                  backgroundColor: blue[400],
                  color: '#fff',
                  borderWidth: 1.5,
                },
              }),
            }),
          }),
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          border: `1px solid`,
          borderRadius: `12px`,
          borderColor: 'transparent',
          backgroundColor: 'transparent',
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: 'auto',
          '& .MuiInputBase-root': {
            height: '100%',
          },
        }),
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          // borderRadius: 8,
          fieldset: {
            borderWidth: 1.25,
            borderRadius: 6,
            borderColor: grey[300],
            '&:focus': {
              borderColor: blue[400],
            },
          },
          p: {
            fontWeight: 'bold',
            marginLeft: '0px',
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: ({ ownerState }) => ({
          padding: '12px 16px',
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          top: '-3px !important',
        }),
        asterisk: {
          color: '#E54D39',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          '&[disabled]': {
            color: grey[400],
            pointerEvents: 'none',
          },
        },
      },
    },
  },
});

// override font size
// theme = createTheme(theme, {
//     typography: {
//         h1: {
//             fontSize: "1.35rem",
//             lineHeight: 1.2,
//             [theme.breakpoints.up("md")]: {
//                 fontSize: "2rem",
//                 lineHeight: 1.25,
//             }
//         },
//         h2: {
//             fontSize: "1.25rem",
//             lineHeight: 1.2,
//             [theme.breakpoints.up("md")]: {
//                 fontSize: "1.75rem",
//                 lineHeight: 1.25,
//             }
//         },
//         h3: {
//             fontSize: "1.125rem",
//             lineHeight: 1.2,
//             [theme.breakpoints.up("md")]: {
//                 fontSize: "1.5rem",
//                 lineHeight: 1.25,
//             }
//         },
//         h4: {
//             fontSize: "1rem",
//             fontWeight: 600,
//         }
//     }
// });

export default theme;
