import { Box, Button, Link, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { green, grey } from '@mui/material/colors';
import dayjs from 'dayjs';
import { getAuthCookie } from '../../../../context/auth';
import client from '../../../../modules/axios';
import { API } from '../../../../config/path';
import { useApp } from '../../../../context/appContext';

const CardProduct = (props) => {
  const { product, setProductCheckout, dataCheckout, setDataCheckout } = props;
  const { snackbar, setSnackbar } = useApp();
  const [isDisplay, setIsDisplay] = useState(false);
  const [applyVoucherBody, setApplyVoucherBody] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const checkout = JSON.parse(localStorage.getItem('checkout'));
    if (checkout) {
      setApplyVoucherBody({
        id_product: checkout.product.id,
        adult: checkout.detail.traveler_summary.adult,
        child: checkout.detail.traveler_summary.child,
        infant: checkout.detail.traveler_summary.infant,
        booking_date: checkout.detail.booking_date,
        voucher: '',
      });
    }
  }, []);

  function handleInputPromo(e) {
    e.preventDefault();
    setIsDisplay(!isDisplay);
  }

  function handleApplyPromo(e) {
    e.preventDefault();

    if (applyVoucherBody.voucher === '') {
      return;
    }

    setIsDisabled(true);

    client
      .post(API.APPLY_VOUCHER, applyVoucherBody, {
        headers: {
          Authorization: `Bearer ${getAuthCookie()}`,
        },
      })
      .then((res) => {
        if (res.data.error) {
          setSnackbar({
            ...snackbar,
            open: true,
            message: res.data.message,
            type: 'error',
          });

          setIsDisabled(false);
          return;
        }

        setDataCheckout({
          ...dataCheckout,
          voucher: applyVoucherBody.voucher,
        });

        const data = res.data.data;
        data.product.thumbnail = product.product_image;

        setProductCheckout(data);

        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Promo code applied',
          type: 'success',
        });
      })
      .catch((err) => {
        setIsDisabled(false);

        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Internal server error',
          type: 'error',
        });
      });
  }

  function handleCancelPromo(e) {
    e.preventDefault();

    if (applyVoucherBody.voucher === '') {
      setIsDisplay(false);
      return;
    }

    setIsDisabled(false);
    setIsDisplay(false);

    setApplyVoucherBody({
      ...applyVoucherBody,
      voucher: '',
    });

    setDataCheckout({
      ...dataCheckout,
      voucher: '',
    });

    if (product.sub_total_price) {
      setProductCheckout({
        ...product,
        total_price: product.sub_total_price,
      });
    }

    setSnackbar({
      ...snackbar,
      open: true,
      message: 'Promo code canceled',
      type: 'success',
    });
  }

  // console.log('product', product);

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: 2,
        p: {
          xs: 2,
          md: 3,
        },
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 1,
          mb: 1.5,
        }}>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}>
          <Box
            component="img"
            sx={{
              width: 50,
              height: 50,
              borderRadius: 1,
            }}
            alt="The tour image"
            src={product?.product.thumbnail}
          />
          <Typography variant="h4" fontWeight={600}>
            {product?.product.title}
          </Typography>
        </Box>
        <Typography
          variant="h4"
          fontWeight={600}
          sx={{
            whiteSpace: 'nowrap',
          }}>
          ${product?.total_price}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
          pb: 3,
          borderBottom: '1px solid' + grey[300],
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
          <PersonOutlineOutlinedIcon
            sx={{
              color: green[800],
              fontSize: '18px',
              width: '18px',
            }}
          />
          <Typography variant="body1">
            {product?.detail.traveler_summary.adult + ' adults'}
            {product?.detail.traveler_summary.child > 0 &&
              `, ${product?.detail.traveler_summary.child} childs`}
            {product?.detail.traveler_summary.infant > 0 &&
              `, ${product?.detail.traveler_summary.infant} infants`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
          <CalendarTodayOutlinedIcon
            sx={{
              color: green[800],
              fontSize: '16px',
              width: '18px',
            }}
          />
          <Typography variant="body1">
            {product && dayjs(product.detail.booking_date).format('ddd, MMM D, YYYY')} &bull;{' '}
            {product?.detail.departure_time}
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          py: 2,
          borderBottom: '1px solid' + grey[300],
        }}>
        {!isDisplay && (
          <Link href="" color={'inherit'} onClick={handleInputPromo}>
            <Typography variant="h4" fontWeight={600}>
              Enter Promo Code
            </Typography>
          </Link>
        )}
        {isDisplay && (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: '0.5rem',
              }}>
              <TextField
                fullWidth
                disabled={isDisabled}
                value={applyVoucherBody.voucher}
                onChange={(e) =>
                  setApplyVoucherBody({
                    ...applyVoucherBody,
                    voucher: e.target.value,
                  })
                }
                placeholder="Enter your promo code"
                variant="outlined"
              />
              <Button
                variant="contained"
                onClick={handleApplyPromo}
                disabled={isDisabled}
                sx={{
                  py: 1.15,
                }}>
                Apply
              </Button>
            </Box>
            <Box>
              <Link href="" color={'secondary.main'} onClick={handleCancelPromo}>
                <Typography
                  variant="body1"
                  display={'inline-block'}
                  fontSize={'.9rem'}
                  fontWeight={500}>
                  Cancel Promo Code
                </Typography>
              </Link>
            </Box>
          </>
        )}
      </Box>

      <Box
        sx={{
          pt: 2,
          mb: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}>
        {product?.sub_total_price && product?.sub_total_price !== product?.total_price && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}>
              <Typography variant="body1">Base price</Typography>
              <Typography variant="body1">${product?.sub_total_price}</Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}>
              <Typography variant="body1">Discount</Typography>
              <Typography variant="body1">{product?.discount}%</Typography>
            </Box>
          </>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: 2,
          }}>
          <Typography variant="h4" fontWeight={600}>
            Total price
          </Typography>
          <Typography
            variant="h3"
            fontWeight={600}
            fontSize={{
              xs: 20,
              md: 22,
            }}>
            ${product?.total_price}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CardProduct;
