import { Button, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Activity from './components/Activity';
import Contact from './components/Contact';
import Overview from './components/Overview';
import { getAuthCookie } from '../../context/auth';
import client from '../../modules/axios';
import { API, PATH } from '../../config/path';
import { useApp } from '../../context/appContext';
import { useNavigate } from 'react-router-dom';
import CardProduct from './components/cardProduct';

const initialDataCheckout = {
  id_product: '',
  first_name_payer: '',
  last_name_payer: '',
  email_payer: '',
  phone_number: '',
  first_name_traveler: '',
  last_name_traveler: '',
  pickup_loc: '',
  special_req: '',
  voucher: '',
  nationality: 'Indonesia',
  adult: 0,
  child: 0,
  infant: 0,
  booking_date: '',
};

const initialSelectedCallingCode = '+62';

const Checkout = () => {
  const navigate = useNavigate();

  const { snackbar, setSnackbar } = useApp();

  const [currSection, setCurrSection] = useState(1);
  const [dataCheckout, setDataCheckout] = useState(initialDataCheckout);
  const [disabledPay, setDisabledPay] = useState(true);
  const [productCheckout, setProductCheckout] = useState(null);
  const [selectedCallingCode, setSelectedCallingCode] = useState(initialSelectedCallingCode);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const checkout = JSON.parse(localStorage.getItem('checkout'));
    if (!checkout) {
      if (getAuthCookie()) {
        navigate(PATH.ACTIVE_BOOKING);
      } else {
        navigate(PATH.DESTINATION);
      }
    } else {
      setProductCheckout(checkout);
      setDataCheckout({
        ...dataCheckout,
        id_product: checkout.product.id,
        adult: checkout.detail.traveler_summary.adult,
        child: checkout.detail.traveler_summary.child,
        infant: checkout.detail.traveler_summary.infant,
        booking_date: checkout.detail.booking_date,
      });
    }
  }, []);

  function handleNext() {
    if (currSection < 2) {
      setCurrSection(currSection + 1);
    }
  }

  function handlePrev(e) {
    e.preventDefault();
    if (currSection > 1) {
      setCurrSection(currSection - 1);
    }
  }

  function handlePay() {
    setDisabledPay(true);
    setIsLoading(true);

    dataCheckout.phone_number = selectedCallingCode + dataCheckout.phone_number;

    let headers = null;
    if (getAuthCookie()) {
      headers = {
        headers: {
          Authorization: 'Bearer ' + getAuthCookie(),
        },
      };
    }

    client
      .post(API.PAY, dataCheckout, headers)
      .then((res) => {
        if (res.data?.error) {
          setSnackbar({
            ...snackbar,
            open: true,
            message: res.data.message,
            type: 'error',
          });
          setDisabledPay(false);
          setIsLoading(false);
          return;
        }

        localStorage.removeItem('checkout');

        window.location.href = res.data;
      })
      .catch(() => {
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Internal server error',
          type: 'error',
        });

        setDisabledPay(false);
        setIsLoading(false);
      });
  }

  return (
    <Box>
      <Grid container>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            backgroundColor: 'white',
          }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              py: 3,
              pl: {
                xs: 3,
                md: 10,
              },
              pr: 3,
            }}>
            <Contact
              currSection={currSection}
              handleNext={handleNext}
              handlePrev={handlePrev}
              dataCheckout={dataCheckout}
              setDataCheckout={setDataCheckout}
              selectedCallingCode={selectedCallingCode}
              setSelectedCallingCode={setSelectedCallingCode}
            />

            <Activity
              currSection={currSection}
              dataCheckout={dataCheckout}
              setDataCheckout={setDataCheckout}
              disabledPay={disabledPay}
              setDisabledPay={setDisabledPay}
              productCheckout={productCheckout}
            />

            <Box
              mb={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={disabledPay}
                onClick={handlePay}
                sx={{
                  px: 6,
                }}>
                PAY NOW
              </Button>
              {isLoading && <Typography variant="body1">Loading...</Typography>}
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            backgroundColor: grey[200],
            py: {
              xs: 6,
              md: 3,
            },
            pr: {
              xs: 2,
              md: 10,
            },
            pl: {
              xs: 2,
              md: 6,
            },
            minHeight: '100vh',
          }}>
          <Box mb={4}>
            <CardProduct
              product={productCheckout}
              setProductCheckout={setProductCheckout}
              dataCheckout={dataCheckout}
              setDataCheckout={setDataCheckout}
            />
          </Box>
          <Overview />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Checkout;
