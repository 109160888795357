import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useCallback, useEffect, useState } from 'react';
import { API } from '../../../config/path';
import { getAuthCookie } from '../../../context/auth';
import client from '../../../modules/axios';

const styles = StyleSheet.create({
  page: {
    padding: 20,
    paddingTop: 20,
    paddingBottom: 20,
    fontSize: '10px',
    fontFamily: 'Helvetica',
    lineHeight: '1.5px',
  },
  tableHeader: {
    width: '100%',
  },
  tableRowHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#677179',
    color: '#fff',
    padding: 10,
    fontFamily: 'Helvetica-Bold',
    fontSize: '16px',
  },
  tableColHeader: {
    width: '50%',
  },
  image: {
    height: 30,
    width: 180,
    objectFit: 'contain',
  },
  table: {
    display: 'table',
    width: '100%',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCell: {
    margin: 'auto',
    padding: 5,
  },
  heading1: {
    fontSize: '12px',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '10px',
  },
  bold: {
    fontFamily: 'Helvetica-Bold',
  },
});

const Column = ({ children, w }) => {
  return (
    <View
      style={{
        width: w,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '5px',
      }}>
      {/* <Text>{children}</Text> */}
      {children}
    </View>
  );
};

const Receipt = () => {
  const [receipt, setReceipt] = useState(null);

  const getReceipt = useCallback(() => {
    client
      .post(
        API.INVOICE,
        {
          order_id: JSON.parse(localStorage.getItem('currOrderId')),
        },
        {
          headers: {
            Authorization: `Bearer ${getAuthCookie()}`,
          },
        },
      )
      .then((res) => {
        if (res.data.error) {
          return;
        }

        setReceipt(res.data.message);
      });
  }, []);

  useEffect(() => {
    getReceipt();
  }, [getReceipt]);

  return (
    <Document>
      <Page size="A5" orientation="landscape" style={styles.page}>
        {receipt && (
          <>
            <View style={styles.tableHeader}>
              <View style={styles.tableRowHeader}>
                <View style={styles.tableColHeader}>
                  <Text>INVOICE ORDER</Text>
                </View>
                <View style={styles.tableColHeader}>
                  <Image src={'/assets/img/logoindoaddict.jpg'} style={styles.image} />
                </View>
              </View>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: 10,
              }}>
              <View
                style={{
                  width: '50%',
                }}>
                <Text style={styles.heading1}>Customer Detail</Text>
                <View>
                  <Text>
                    Name : <Text style={styles.bold}>{receipt.customer_detail.name}</Text>
                  </Text>
                  <Text>
                    Phone : <Text style={styles.bold}>{receipt.customer_detail.phone}</Text>
                  </Text>
                  <Text>
                    Email : <Text style={styles.bold}>{receipt.customer_detail.email}</Text>
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '50%',
                }}>
                <Text style={styles.heading1}>Receipt</Text>
                <View>
                  <Text>
                    Number : <Text style={styles.bold}>{receipt.receipt.number}</Text>
                  </Text>
                  <Text>
                    Date : <Text style={styles.bold}>{receipt.receipt.date}</Text>
                  </Text>
                  <Text>
                    Status : <Text style={styles.bold}>{receipt.receipt.status}</Text>
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                marginTop: 20,
                marginBottom: 4,
              }}>
              <Text
                style={{
                  fontFamily: 'Helvetica-Bold',
                  fontSize: '12px',
                  paddingLeft: '10px',
                }}>
                Purchase Detail
              </Text>
            </View>

            <View style={styles.table}>
              {/* thead */}
              <View
                style={{
                  backgroundColor: '#677179',
                  fontFamily: 'Helvetica-Bold',
                  fontSize: '12px',
                  color: '#fff',
                  ...styles.tableRow,
                }}>
                <Column w="5%">
                  <Text>No.</Text>
                </Column>
                <Column w="10%">
                  <Text>Code</Text>
                </Column>
                <Column w="50%">
                  <Text>Item Description</Text>
                </Column>
                <Column w="10%">
                  <Text>Qty</Text>
                </Column>
                <Column w="15%">
                  <Text>Unit Price (USD)</Text>
                </Column>
                <Column w="10%">
                  <Text>Total (USD)</Text>
                </Column>
              </View>

              {/* tbody */}
              <View
                style={{
                  ...styles.tableRow,
                  borderBottom: '0.5px solid #ababab',
                }}>
                <Column w="5%">
                  <Text>1.</Text>
                </Column>
                <Column w="10%">
                  <Text>{receipt.detail.product_code}</Text>
                </Column>
                <Column w="50%">
                  <Text>{receipt.detail.product_name}</Text>
                  <Text>
                    {receipt.detail.total_traveler} PAX at {receipt.detail.booking_date}
                  </Text>
                </Column>
                <Column w="10%">
                  <Text>{receipt.detail.qty_adult} (Adult)</Text>
                  <Text>{receipt.detail.qty_child} (Child)</Text>
                  <Text>{receipt.detail.qty_infant} (Infant)</Text>
                </Column>
                <Column w="15%">
                  <Text>${receipt.detail.unit_price_adult}</Text>
                  <Text>${receipt.detail.unit_price_child}</Text>
                  <Text>${receipt.detail.unit_price_infant}</Text>
                </Column>
                <Column w="10%">
                  <Text>${receipt.detail.total_price_adult}</Text>
                  <Text>${receipt.detail.total_price_child}</Text>
                  <Text>${receipt.detail.total_price_infant}</Text>
                </Column>
              </View>

              {/* tfoot */}
              <View
                style={{
                  ...styles.tableRow,
                }}>
                <Column w="5%" />
                <Column w="10%" />
                <Column w="50%" />
                <Column w="10%" />
                <Column w="15%">
                  <Text style={{ marginBottom: 3, ...styles.bold }}>Sub Total</Text>
                  <Text style={styles.bold}>Diskon</Text>
                  <Text style={styles.bold}>Total</Text>
                </Column>
                <Column w="10%">
                  <Text style={{ marginBottom: 3 }}>${receipt.detail.sub_total}</Text>
                  <Text>{receipt.detail.discount}%</Text>
                  <Text style={styles.bold}>${receipt.detail.total_price}</Text>
                </Column>
              </View>
            </View>
          </>
        )}
      </Page>
    </Document>
  );
};

export default Receipt;
