import React from 'react';

const Hr = (props) => {
  return (
    <hr
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        height: '2.5px',
        border: 'none',
      }}
    />
  );
};

export default Hr;
