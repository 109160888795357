import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, Typography, Link } from '@mui/material';
import { PATH } from '../../../../config/path';

const ArticleCard = (props) => {
  const { blog } = props;

  // console.log('blog', blog);

  return (
    <Link href={`${PATH.BLOGS}/${blog.slug}`} underline="none">
      <Card
        sx={{
          bgcolor: 'white',
          borderRadius: 0,
          height: '100%',
        }}>
        <CardActionArea>
          <CardMedia
            component="img"
            src={blog.thumbnail}
            height={'240'}
            alt="thumbnail"
            loading="lazy"
            sx={{
              bgcolor: 'grey',
              objectFit: 'cover',
            }}
          />
          <CardContent>
            <Typography gutterBottom variant="body2" color="black">
              {blog.category}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {blog.title}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default ArticleCard;
