import { Link, Typography } from '@mui/material';
import { blue, green, grey } from '@mui/material/colors';
import { Box } from '@mui/system';
import React from 'react';
import { PATH } from '../../../../config/path';
import { Link as ReactRouterLink } from 'react-router-dom';

const BookingCard = (props) => {
  const { order } = props;

  return (
    <Box
      sx={{
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: 2,
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          backgroundColor: grey[200],
          py: 2,
          px: {
            xs: 2,
            md: 3,
          },
          color: grey[600],
        }}>
        <Typography variant="body1">
          Booking ID: <b>{order?.booking_id}</b>
        </Typography>
        <Typography variant="body1" color={'black'}>
          ${order?.price}
        </Typography>
      </Box>

      <Box
        sx={{
          py: 2,
          px: {
            xs: 2,
            md: 3,
          },
        }}>
        <Typography
          variant="body1"
          sx={{
            maxWidth: {
              xs: '100%',
              md: '50%',
            },
          }}>
          {order?.product_name}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          backgroundColor: grey[200],
          py: 2,
          px: {
            xs: 2,
            md: 3,
          },
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: green[600],
            borderRadius: 5,
            py: 0.2,
            px: 2,
          }}>
          <Typography
            variant="body1"
            color="white"
            sx={{
              fontSize: {
                xs: '0.75rem',
                md: '0.875rem',
              },
            }}>
            {order?.tour_status}
          </Typography>
        </Box>
        <Link
          component={ReactRouterLink}
          to={`${PATH.HISTORY_BOOKING}/${order?.order_id}`}
          underline="hover"
          color={blue[600]}>
          <Typography variant="body1" noWrap>
            See Details
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default BookingCard;
