import React from 'react';
import AdditionalInfo from './components/AdditionalInfo';
import Cancellation from './components/Cancellation';
import Expect from './components/Expect';
import Faq from './components/Faq';
import Features from './components/Features';
import Gallery from './components/Gallery';
import Header from './components/Header';
import Included from './components/Included';
import Meeting from './components/Meeting';
import Overview from './components/Overview';
import RelatedTour from './components/RelatedTour';
import Reviews from './components/Reviews';
import { Box } from '@mui/material';

const Product = (props) => {
  return (
    <Box mb={10}>
      <Header {...props} />
      <Gallery {...props} />
      <Features {...props} />
      <Overview {...props} />
      <Included {...props} />
      <Meeting {...props} />
      <Expect {...props} />
      <AdditionalInfo {...props} />
      <Cancellation />
      <Faq {...props} />
      <Reviews {...props} />
      <RelatedTour {...props} />
    </Box>
  );
};

export default Product;
