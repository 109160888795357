import {
  Box,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import Number from '../../../../components/Number';
import { API } from '../../../../config/path';
import checkoutContactSchema from '../../../../modules/validation-schema/checkoutContactSchema';
import BtnNext from '../NextButton';
import axios from 'axios';

const Contact = (props) => {
  const {
    currSection,
    handleNext,
    handlePrev,
    dataCheckout,
    setDataCheckout,
    selectedCallingCode,
    setSelectedCallingCode,
  } = props;
  const [callingCodes, setCallingCodes] = useState(null);
  const [isGetData, setIsGetData] = useState(false);

  const getCallingCodes = useCallback(() => {
    setIsGetData(true);

    axios.get(API.COUNTRIES).then((res) => {
      setCallingCodes(
        res.data.map((item) => {
          return {
            code: item.dial_code,
            name: item.name,
          };
        }),
      );

      setIsGetData(false);
    });
  }, []);

  useEffect(() => {
    getCallingCodes();
  }, [getCallingCodes]);

  const formik = useFormik({
    initialValues: {
      first_name_payer: dataCheckout.first_name_payer,
      last_name_payer: dataCheckout.last_name_payer,
      email_payer: dataCheckout.email_payer,
      phone_number: dataCheckout.phone_number,
      nationality: dataCheckout.nationality,
    },
    enableReinitialize: true,
    validationSchema: checkoutContactSchema,
    onSubmit: (values) => {
      handleNext();
      setDataCheckout({
        ...dataCheckout,
        ...values,
      });
    },
  });

  return (
    <>
      {isGetData && <Typography variant="body1">Retrieving data! Please wait...</Typography>}

      {currSection === 1 && callingCodes && (
        <Box
          sx={{
            border: '1px solid' + green[800],
            p: 2,
            pb: 6,
            borderRadius: 2,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              mb: 3,
            }}>
            <Number i={1} filled={true} />
            <Typography variant="h3" fontWeight={600}>
              Contact Details
            </Typography>
          </Box>

          <Typography variant="body1" mb={2}>
            We'll use this information to send you confirmation and updates about your booking
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" mb={1}>
                  First Name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="first_name_payer"
                  value={formik.values.first_name_payer}
                  onChange={formik.handleChange}
                  error={formik.touched.first_name_payer && Boolean(formik.errors.first_name_payer)}
                  helperText={formik.touched.first_name_payer && formik.errors.first_name_payer}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body1" mb={1}>
                  Last Name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  name="last_name_payer"
                  value={formik.values.last_name_payer}
                  onChange={formik.handleChange}
                  error={formik.touched.last_name_payer && Boolean(formik.errors.last_name_payer)}
                  helperText={formik.touched.last_name_payer && formik.errors.last_name_payer}
                />
              </Grid>
            </Grid>

            <Box mb={2}>
              <Typography variant="body1" mb={1}>
                Email
              </Typography>
              <TextField
                fullWidth
                variant="outlined"
                name="email_payer"
                value={formik.values.email_payer}
                onChange={formik.handleChange}
                error={formik.touched.email_payer && Boolean(formik.errors.email_payer)}
                helperText={formik.touched.email_payer && formik.errors.email_payer}
              />
            </Box>

            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" mb={1}>
                  Phone Number
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 1,
                  }}>
                  <Select
                    value={selectedCallingCode}
                    onChange={(e) => {
                      setSelectedCallingCode(e.target.value);
                      formik.setFieldValue(
                        'nationality',
                        callingCodes.find(({ code }) => code === e.target.value).name,
                      );
                    }}
                    sx={{
                      width: {
                        xs: '7rem',
                        sm: 'auto',
                        md: 'auto',
                        lg: '7rem',
                      },
                    }}>
                    {callingCodes &&
                      callingCodes.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item.code}>
                            ({item.code}) {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <TextField
                    fullWidth
                    variant="outlined"
                    name="phone_number"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                    error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" mb={1}>
                  Nationality
                </Typography>
                <Select
                  fullWidth
                  variant="outlined"
                  name="nationality"
                  value={formik.values.nationality}
                  onChange={formik.handleChange}
                  error={formik.touched.nationality && Boolean(formik.errors.nationality)}>
                  <MenuItem value="" disabled>
                    Select country
                  </MenuItem>
                  {callingCodes.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {formik.touched.nationality && formik.errors.nationality ? (
                  <FormHelperText
                    sx={{
                      color: red[700],
                      fontWeight: 'bold',
                    }}>
                    {formik.touched.nationality && formik.errors.nationality}
                  </FormHelperText>
                ) : null}
              </Grid>
            </Grid>

            <BtnNext type="submit">Next</BtnNext>
          </form>
        </Box>
      )}

      {currSection !== 1 && (
        <Box
          sx={{
            border: '1px solid' + grey[400],
            p: 2,
            borderRadius: 2,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              mb: 2,
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}>
              <Number i={1} filled={false} />
              <Typography variant="h3" color={grey[700]} fontWeight={600}>
                Contact Details
              </Typography>
            </Box>
            <Link href="" onClick={handlePrev} underline="none">
              <Typography variant="body1" color="secondary">
                Edit
              </Typography>
            </Link>
          </Box>

          <Typography variant="body1" mb={2} fontWeight="bold" color={grey[700]}>
            {dataCheckout.first_name_payer} {dataCheckout.last_name_payer}
          </Typography>
          <Typography variant="body1" mb={1} color={grey[700]}>
            Email: {dataCheckout.email_payer}
          </Typography>
          <Typography variant="body1" color={grey[700]}>
            Phone: {selectedCallingCode + dataCheckout.phone_number}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Contact;
