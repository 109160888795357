import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Calendar from '../../../../components/Calendar/LandingCalendar';

const init90Days = {
  label: 'past_90_days',
  end: dayjs(new Date()).subtract(1, 'day').format('YYYY-MM-DD'),
  start: dayjs(new Date()).subtract(90, 'day').format('YYYY-MM-DD'),
};

const initLastMonth = {
  label: 'last_month',
  name: dayjs().subtract(1, 'month').format('MMM YYYY'),
  start: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
  end: dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
};

const init2Month = {
  label: 'last_2_month',
  name: dayjs().subtract(2, 'month').format('MMM YYYY'),
  start: dayjs().subtract(2, 'month').startOf('month').format('YYYY-MM-DD'),
  end: dayjs().subtract(2, 'month').endOf('month').format('YYYY-MM-DD'),
};

const initCustom = {
  label: 'custom',
  start: '',
  end: '',
};

const Filters = (props) => {
  const [past90, setPast90] = useState(init90Days);
  const [lastMonth, setLastMonth] = useState(initLastMonth);
  const [last2Month, setLast2Month] = useState(init2Month);
  const [custom, setCustom] = useState(initCustom);
  const [isDialogDate, setIsDialogDate] = useState(false);

  useEffect(() => {
    props.setFilterByDate(past90);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        overflowX: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}>
      {props.filterByDate && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: {
              xs: 1,
              md: 2,
            },
            flexWrap: 'nowrap',
          }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => props.setFilterByDate(past90)}
            selected={props.filterByDate.label === past90.label}>
            Past 90 Days
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.filterByDate.label === lastMonth.label) {
                props.setFilterByDate(past90);
                return;
              }
              props.setFilterByDate(lastMonth);
            }}
            selected={props.filterByDate.label === lastMonth.label}>
            {lastMonth.name}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (props.filterByDate.label === last2Month.label) {
                props.setFilterByDate(past90);
                return;
              }
              props.setFilterByDate(last2Month);
            }}
            selected={props.filterByDate.label === last2Month.label}>
            {last2Month.name}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setIsDialogDate(true)}
            selected={props.filterByDate.label === custom.label}>
            Customize Date
          </Button>
          <Dialog open={isDialogDate} onClose={() => setIsDialogDate(false)}>
            <DialogContent
              sx={{
                minWidth: {
                  md: 400,
                },
              }}>
              <Box mb={2}>
                <Typography variant="body1" mb={1}>
                  Start Date
                </Typography>
                <Calendar
                  maxDate={dayjs()}
                  onChange={(e) => {
                    setCustom({
                      ...custom,
                      start: e,
                    });
                  }}
                />
              </Box>
              <Box mb={2}>
                <Typography variant="body1" mb={1}>
                  End Date
                </Typography>
                <Calendar
                  maxDate={dayjs()}
                  onChange={(e) => {
                    setCustom({
                      ...custom,
                      end: e,
                    });
                  }}
                />
              </Box>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    props.setFilterByDate(custom);
                    setIsDialogDate(false);
                  }}
                  disabled={custom.start === '' || custom.end === ''}>
                  Apply
                </Button>
              </Box>
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

export default Filters;
