import { Box } from '@mui/material';
import Filters from './components/Filters';
import Paginator from './components/Pagination';

const App = (props) => (
  <Box mb={10}>
    <Filters
      pageSettings={props.pageSettings}
      setPageSettings={props.setPageSettings}
      setIsSearch={props.setIsSearch}
    />
    <Paginator
      products={props.products}
      pageSettings={props.pageSettings}
      setPageSettings={props.setPageSettings}
      isGetProducts={props.isGetProducts}
    />
  </Box>
);

export default App;
