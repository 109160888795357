import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import dayjs from 'dayjs';
import { green } from '@mui/material/colors';

const CheckoutSummary = (props) => {
  const { productCheckout } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1.5,
      }}>
      <Box
        component="img"
        sx={{
          width: {
            xs: 120,
            md: 200,
          },
          height: 'auto',
          borderRadius: 1,
        }}
        alt="The tour image"
        src={productCheckout?.product.thumbnail}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}>
        <Typography variant="h4">{productCheckout?.product.title}</Typography>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}>
          <PersonOutlineOutlinedIcon
            sx={{
              color: green[800],
              fontSize: '16px',
              pt: 0.4,
            }}
          />
          <Typography variant="body1">
            {productCheckout?.detail.traveler_summary.adult + ' adults'}
            {productCheckout?.detail.traveler_summary.child > 0 &&
              `, ${productCheckout?.detail.traveler_summary.child} childs`}
            {productCheckout?.detail.traveler_summary.infant > 0 &&
              `, ${productCheckout?.detail.traveler_summary.infant} infants`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
          }}>
          <CalendarTodayOutlinedIcon
            sx={{
              color: green[800],
              fontSize: '16px',
              pt: 0.4,
            }}
          />
          <Typography variant="body1">
            {productCheckout &&
              dayjs(productCheckout.detail.booking_date).format('ddd, MMM D, YYYY')}{' '}
            &bull; {productCheckout?.detail.departure_time}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckoutSummary;
