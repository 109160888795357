import axios from 'axios';
import { destroyAuthCookie, destroyAuthUser } from '../../context/auth';
import { API_BASE_URL, PATH } from '../../config/path';

const client = axios.create({
  baseURL: API_BASE_URL,
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // if error 401 (Unauthorized)
    // destroy auth token and user data from cookies
    if (error.response && error.response.status === 401) {
      destroyAuthCookie();
      destroyAuthUser();

      window.location.href = PATH.LOGIN;
    }

    return Promise.reject(error);
  },
);

export default client;
