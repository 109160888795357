import { Box, Container } from '@mui/material';
import React from 'react';

const NotFound = () => {
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          minHeight: '100vh',
          alignItems: 'center',
        }}>
        <Box textAlign={'center'}>
          <h1>404 - Page Not Found</h1>
          <p>The page you're looking for doesn't exist.</p>
        </Box>
      </Box>
    </Container>
  );
};

export default NotFound;
