import React, { useState, useEffect } from 'react';
import { Container, Grid, Button, Box, Typography } from '@mui/material';
import Calendar from '../../../../components/Calendar/LandingCalendar';
import Availability from '../Availability';
import styles from './styles.module.scss';
import Hr from '../../../../components/Hr';
import { grey, red, teal } from '@mui/material/colors';
import GallerySlick from './GallerySlick';
import dayjs from 'dayjs';
import client from '../../../../modules/axios';
import { API } from '../../../../config/path';
import { useApp } from '../../../../context/appContext';
import LearnMoreCancellationButton from '../LearnMoreCancellationButton';

const initDate = dayjs().add(1, 'day').format('YYYY-MM-DD');

const Gallery = (props) => {
  const initDataAvability = {
    id_product: null,
    adult: 1,
    child: 0,
    infant: 0,
  };
  const { snackbar, setSnackbar } = useApp();

  const [date, setDate] = useState(initDate);
  const [travelers, setTravelers] = useState({});
  const [productImages, setProductImages] = useState([]);
  const [isSShowDetail, setIsShowDetail] = useState(false);
  const [dataAvability, setDataAvability] = useState(initDataAvability);
  const [resAvabilityDetail, setResAvabilityDetail] = useState(null);

  useEffect(() => {
    if (props.product) {
      let images = [];
      props.product.product_gallery?.map((image) => images.push(image.image));

      setProductImages([props.product.product_image, ...images]);

      setDataAvability({
        ...dataAvability,
        id_product: props.product.id,
      });
    }
  }, [props.product]);

  const handleClick = (e) => {
    e.preventDefault();

    client
      .post(API.AVABILITY_DETAIL, dataAvability)
      .then((res) => {
        if (res.data.success) {
          setResAvabilityDetail(res.data.data);
          setIsShowDetail(true);

          const element = document.getElementById('product-show-detail');
          if (element) {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }

          return;
        }

        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Something went wrong',
          type: 'error',
        });
      })
      .catch((err) => {
        console.log('err', err);
        setSnackbar({
          ...snackbar,
          open: true,
          message: 'Internal server error',
          type: 'error',
        });
      });
  };

  return (
    <Container>
      <Box
        sx={{
          my: 2,
        }}>
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={8}>
            {productImages.length > 0 && <GallerySlick images={productImages} />}
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: grey[200],
                height: '100%',
                borderRadius: 2,
              }}>
              <Box
                sx={{
                  p: 2,
                }}>
                <Box
                  sx={{
                    borderBottom: '1px solid' + grey[500],
                    mb: 2,
                  }}>
                  <Typography variant="body1">
                    From <span className={styles.price}>${props.product.start_price}</span>
                  </Typography>
                  <Typography variant="body2" mb={2}>
                    Price Varies by group size
                  </Typography>
                  <Typography variant="body1" color={teal[900]}>
                    Lowest Price Guarantee
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    mb: 1,
                  }}>
                  <Typography variant="h4">Select Date and Travelers</Typography>
                  <Calendar
                    initDate={date}
                    minDate={dayjs()}
                    onChange={(e) => {
                      setDate(e);
                    }}
                  />
                  <Availability
                    traveler={props.product.price_check}
                    onChange={(e) => {
                      setTravelers(e);
                      setDataAvability({
                        ...dataAvability,
                        adult: e.adult,
                        child: e.child,
                        infant: e.infant,
                      });
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={(e) => handleClick(e)}
                    sx={{
                      backgroundColor: red[800],
                      borderColor: red[800],
                      '&:hover': {
                        backgroundColor: red[900],
                        borderColor: red[900],
                      },
                    }}>
                    Book Now
                  </Button>
                </Box>
                <Typography variant="body2" fontWeight={600}>
                  Free cancellation
                </Typography>
                <Typography variant="body2">
                  Up to 24 hours in advance. <LearnMoreCancellationButton />
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {isSShowDetail && (
        <Box
          id="product-show-detail"
          sx={{
            px: {
              md: 2,
            },
            py: 1,
          }}>
          <Grid
            container
            sx={{
              border: '1px solid' + grey[400],
              borderRadius: 1.5,
            }}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                borderRight: {
                  xs: 'none',
                  md: '1px solid' + grey[400],
                },
                borderBottom: {
                  xs: '1px solid' + grey[400],
                  md: 'none',
                },
                p: {
                  xs: 2,
                  md: 4,
                },
              }}>
              <Typography
                variant="h3"
                sx={{
                  mb: {
                    xs: 2,
                    md: 4,
                  },
                }}>
                {resAvabilityDetail?.product_name}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: {
                    xs: 1,
                    md: 2,
                  },
                }}>
                Pickup included
              </Typography>
              <Button variant="contained">{resAvabilityDetail?.departure_time}</Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              sx={{
                p: {
                  xs: 2,
                  md: 4,
                },
              }}>
              <Box
                textAlign={'right'}
                sx={{
                  mb: {
                    xs: 4,
                    md: 8,
                  },
                }}>
                <Typography variant="body1" fontWeight={'bold'}>
                  ${resAvabilityDetail?.total_price}
                </Typography>
                {resAvabilityDetail?.adult_pax && (
                  <Typography variant="body2">
                    {resAvabilityDetail?.adult_pax} Adult(s) x ${resAvabilityDetail?.adult_price}
                  </Typography>
                )}
                {resAvabilityDetail?.child_pax !== 0 && (
                  <Typography variant="body2">
                    {resAvabilityDetail?.child_pax} Child(s) x ${resAvabilityDetail?.child_price}
                  </Typography>
                )}
                {resAvabilityDetail?.infant_pax !== 0 && (
                  <Typography variant="body2">
                    {resAvabilityDetail?.infant_pax} Infant(s) x ${resAvabilityDetail?.infant_price}
                  </Typography>
                )}
              </Box>
              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  props.handleCheckAvailibility(date, travelers);
                }}>
                Book Now
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      <Hr />
    </Container>
  );
};

export default Gallery;
