import React, { useState } from 'react';
import { Box, Button, Container, Grid, Link, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useFormik } from 'formik';
import registerSchema from '../modules/validation-schema/registerSchema';
import client from '../modules/axios';
import { API, PATH } from '../config/path';
import { useNavigate } from 'react-router-dom';
import { useApp } from '../context/appContext';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import SEO from '../modules/seo';
import { Link as ReactRouterLink } from 'react-router-dom';

const Register = () => {
  const { snackbar, setSnackbar } = useApp();
  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      password_confirm: '',
    },
    validationSchema: registerSchema,
    onSubmit: (values) => {
      setIsDisabled(true);

      client
        .post(API.REGISTER, values)
        .then((res) => {
          navigate(PATH.LOGIN);
          setSnackbar({
            ...snackbar,
            open: true,
            message: 'Register successfully',
            type: 'success',
          });
        })
        .catch((err) => {
          let error = err.response.data.error;
          let firstError = Object.keys(error)[0];

          if (error[firstError]) {
            setSnackbar({
              ...snackbar,
              open: true,
              message: error[firstError][0],
              type: 'error',
            });
          }

          setIsDisabled(false);
        });
    },
  });

  return (
    <>
      <SEO
        title="Register | Indoaddict"
        description="Login or sign up to access exclusive deals and discounts on our exciting destination and tour packages! Our expert team is here to help you plan your next adventure."
        name="Indoaddict"
        type="Indoaddict"
      />
      <Container maxWidth="md">
        <Box
          sx={{
            mt: 3,
            border: '1px solid' + grey[300],
            borderRadius: 1,
          }}>
          <Box
            sx={{
              backgroundColor: grey[100],
              py: 1.5,
              px: 2.5,
            }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 2,
              }}>
              <Link
                component={ReactRouterLink}
                to={PATH.HOME}
                color={'primary'}
                underline="hover"
                align="right">
                <Button
                  variant="text"
                  color="primary"
                  startIcon={<ArrowBackIcon />}
                  sx={{
                    py: 0,
                  }}>
                  Home
                </Button>
              </Link>
              <Typography variant="body1">Register</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              py: 2,
              px: 2.5,
            }}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">Name</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">Email Address</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">Password</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} mb={2}>
                <Grid item xs={12} sm={4}>
                  <Typography variant="body1">Confirm Password</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="password_confirm"
                    type="password"
                    value={formik.values.password_confirm}
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    error={
                      formik.touched.password_confirm && Boolean(formik.errors.password_confirm)
                    }
                    helperText={formik.touched.password_confirm && formik.errors.password_confirm}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}></Grid>
                <Grid item xs={12} sm={8}>
                  <Box
                    sx={{
                      pt: 1,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                    }}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isDisabled}
                      loading={isDisabled}
                      loadingIndicator="Loading…"
                      sx={{
                        px: 4,
                      }}>
                      Register
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Register;
