import React from 'react';
import { Box, Grid } from '@mui/material';
import { useApp } from '../../../../context/appContext';

const Badge = ({ src, link }) => {
  return (
    <>
      <Box
        sx={{
          height: { xs: 60, sm: 80, md: 100, lg: 120 },
          width: 'auto',
          mb: 2,
        }}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img
            alt="Partner icon"
            src={src}
            width={'100%'}
            height={'100%'}
            loading="lazy"
            style={{
              objectFit: 'contain',
            }}
          />
        </a>
      </Box>
      {/* <Typography
        variant="h2"
        mb={1}
        sx={{
          fontSize: {
            xs: '1.25rem',
            fontWeight: 500,
          },
        }}>
        {title}
      </Typography>
      <Typography variant="body1">{desc}</Typography> */}
    </>
  );
};

const App = () => {
  const { getImageAsset } = useApp();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems={'baseline'}
      rowSpacing={{
        xs: 6,
        sm: 0,
      }}
      columnSpacing={{
        xs: 0,
        sm: 6,
      }}>
      <Grid
        item
        xs={12}
        sm={3}
        sx={{
          textAlign: 'center',
        }}>
        <Badge
          src={getImageAsset('bromo-trail')}
          link="https://bromotrail.com/"
          // title="Find authentic experiences"
          // desc="Follow your curiosity, feel the joy of real discoverieses, and explore more of the world."
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={3}
        sx={{
          textAlign: 'center',
        }}>
        <Badge
          src={getImageAsset('viator')}
          link="https://www.tripadvisor.com/Attraction_Review-g14782503-d20352946-Reviews-Indoaddict-Yogyakarta_Yogyakarta_Region_Java.html"
          // title="Quality is Number one"
          // desc="High quality standard, with lots of honest reviews."
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={3}
        sx={{
          textAlign: 'center',
        }}>
        <Badge
          src={getImageAsset('get-your-guide')}
          link="https://www.getyourguide.com/id-id/s/?q=supplier:376377"
          // title="Explore your adventures"
          // desc="Enjoy personalized recommendations and itineraries, iconic attractions, and more."
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={3}
        sx={{
          textAlign: 'center',
        }}>
        <Badge
          src={getImageAsset('jogja-tour-package')}
          link="https://jogjatourpackage.com/"
          // title="Explore your adventures"
          // desc="Enjoy personalized recommendations and itineraries, iconic attractions, and more."
        />
      </Grid>
    </Grid>
  );
};

export default App;
