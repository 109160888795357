import { Box, Container, Link, Typography } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';
import React from 'react';

const Header = (props) => {
  return (
    <Container
      sx={{
        mt: 2,
      }}>
      <Typography variant="h1" mb={2}>
        {props.product.title}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: {
            xs: 'flex-start',
            sm: 'center',
          },
          flexDirection: {
            xs: 'column',
            sm: 'row',
          },
          gap: {
            xs: 1,
          },
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
          }}>
          <Box>
            <Typography variant="body2">{props.product.city}</Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}>
          <Link
            href="#"
            underline="none"
            color={'inherit'}
            sx={{
              display: 'none',
              alignItems: 'center',
              gap: 0.5,
            }}>
            <IosShareIcon fontSize={'small'} /> <Typography variant="body2">Share</Typography>
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Header;
