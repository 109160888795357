import ContactUs from '../layout/ContactUs';
import SEO from '../modules/seo';

const App = () => {
  return (
    <>
      <SEO
        title="Contact Us | Indoaddict"
        description="Get in touch with us to learn more about our exciting destination and tour packages! Our expert team is here to answer your questions and help you plan your next adventure."
        name="Indoaddict"
        type="Indoaddict"
      />
      <ContactUs />
    </>
  );
};

export default App;
