import React, { useState, useLayoutEffect, useCallback } from 'react';
import client from '../modules/axios';
import SEO from '../modules/seo';
import Index from '../layout/Index';
import { API } from '../config/path';

const App = () => {
  const [topDestinations, setTopDestinations] = useState([]);
  const [topRated, setTopRated] = useState([]);
  const [recommendedTours, setRecommendedTours] = useState([]);

  const getTopDestinations = useCallback(() => {
    client
      .get(API.TOP_DESTINATION)
      .then((response) => {
        const data = response.data.success;

        setTopDestinations(data);
        // Snackbar.success(response.statusText);
      })
      .catch((_error) => {
        // Snackbar.error(error.statusText);
      });
  }, []);

  const getTopRatedTours = useCallback(() => {
    client
      .get(API.TOP_RATED)
      .then((response) => {
        const data = response.data.success;

        setTopRated(data);
        // Snackbar.success(response.statusText);
      })
      .catch((_error) => {
        // Snackbar.error(error.statusText);
      });
  }, []);

  const getRecommendedTours = useCallback(() => {
    client
      .get(API.RECOMMENDED_TOURS)
      .then((response) => {
        const data = response.data.success;

        setRecommendedTours(data);
      })
      .catch((_error) => {
        // Snackbar.error(error.statusText);
      });
  }, []);

  useLayoutEffect(() => {
    getTopDestinations();
    getTopRatedTours();
    getRecommendedTours();
  }, [getTopDestinations, getTopRatedTours, getRecommendedTours]);

  // console.log('topDestinations', topDestinations);
  // console.log('topRated', topRated);

  return (
    <div>
      <SEO
        title="Indoaddict"
        description="Explore our exciting destination and tour packages! Discover new places, cultures, and experiences with our expert guides. Book your next adventure today."
        name="Indoaddict"
        type="Indoaddict"
      />
      <Index
        topRatedTours={topRated}
        topDestinations={topDestinations}
        recommendedTours={recommendedTours}
      />
    </div>
  );
};

export default App;
