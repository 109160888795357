import React, { useState, useLayoutEffect, useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Paper, TextField, InputAdornment, Button, Box, Container } from '@mui/material';
import Search from '@mui/icons-material/Search';
import client from '../../../../modules/axios';
import styles from './styles.module.scss';
import { grey } from '@mui/material/colors';
import { PATH } from '../../../../config/path';
import { useApp } from '../../../../context/appContext';

const FilterIcon = (props) => {
  return (
    <Box
      component={'img'}
      src={props.src}
      sx={{
        width: 25,
        height: 25,
      }}
    />
  );
};

const Filters = (props) => {
  const { getImageAsset } = useApp();

  const [filters, setFilters] = useState([]);
  const [search, setSearch] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [scrollYPosition, setScrollYPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollYPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const rebaseURL = () => {
    if (typeof window != 'undefined' && searchParams.has('search')) {
      searchParams.delete('search');
      setSearchParams(searchParams);
      window.history.replaceState(null, null, '/destination');
    }
  };

  const setPageSetting = (key, value) => {
    props.setPageSettings({
      ...props.pageSettings,
      [key]: value,
    });
  };

  const getFilters = () => {
    return client.get(`api/category`);
  };

  const retriveFilter = useCallback(() => {
    getFilters().then((response) => {
      setFilters(response.data.data);
    });
  }, []);

  const handleBtnFilter = (name) => {
    if (props.pageSettings.category === name) {
      setPageSetting('category', null);
      props.setIsSearch(true);
      return;
    }
    setPageSetting('category', name);
    props.setIsSearch(true);
  };

  useLayoutEffect(() => {
    retriveFilter();
  }, [retriveFilter]);

  return (
    <Paper className={styles.container} elevation={0}>
      <Box
        sx={{
          pt: {
            xs: 2,
            md: 3,
          },
          pb: {
            xs: 2,
            md: 2,
          },
          backgroundColor: scrollYPosition > 0 ? grey[200] : 'transparent',
        }}>
        <Container>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'nowrap',
              overflowX: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'nowrap',
                gap: {
                  xs: 1,
                  sm: 2,
                },
                mb: 1,
              }}>
              {filters?.map(({ id, name }) => {
                return (
                  <Button
                    key={id}
                    variant="outlined"
                    selected={props.pageSettings.category === name}
                    onClick={() => handleBtnFilter(name)}
                    sx={{
                      whiteSpace: 'nowrap',
                      flexWrap: 'nowrap',
                    }}
                    startIcon={(() => {
                      switch (name) {
                        case 'Adventure':
                          return <FilterIcon src={getImageAsset('adventure')} />;
                        case 'Daily tour':
                          return <FilterIcon src={getImageAsset('daily-tour')} />;
                        case 'Multi tour':
                          return <FilterIcon src={getImageAsset('multiday-tour')} />;
                        case 'Overland tour':
                          return <FilterIcon src={getImageAsset('overland')} />;
                        default:
                          return null;
                      }
                    })()}>
                    {name}
                  </Button>
                );
              })}
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: {
                xs: 1,
                sm: 2,
              },
              py: 1,
            }}>
            <TextField
              placeholder="Search destinations, tours, activities"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setPageSetting('search', search);
                  rebaseURL();
                  props.setIsSearch(true);
                }
              }}
              variant="outlined"
              fullWidth
              sx={{
                backgroundColor: '#fff',
                borderRadius: 1.5,
                '& .MuiOutlinedInput-input': {
                  pl: 0,
                },
              }}
            />

            <Button
              variant="contained"
              onClick={() => {
                setPageSetting('search', search);
                rebaseURL();
                props.setIsSearch(true);
              }}
              sx={{
                py: '9px',
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
              }}>
              Search
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                window.location.href = PATH.DESTINATION;
              }}
              sx={{
                py: '8px',
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
              }}>
              Reset
            </Button>
          </Box>
        </Container>
      </Box>
    </Paper>
  );
};

export default Filters;
