import { Button } from '@mui/material';
import React from 'react';

const BtnNext = (props) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: 'secondary.main',
      }}
      {...props}>
      {props.children}
    </Button>
  );
};

export default BtnNext;
