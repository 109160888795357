import React from 'react';
import BookingDetails from '../layout/BookingDetails';

const App = () => {
  return (
    <>
      <BookingDetails />
    </>
  );
};

export default App;
