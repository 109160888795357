import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import blue from '../../theme/colors/blue';
import { Box } from '@mui/material';

const Index = ({ ...props }) => (
  <>
    <Header {...props} />
    <main
      style={{
        minHeight: '70vh',
      }}>
      {<Outlet />}
    </main>
    <Box
      sx={{
        height: {
          md: '20vh',
        },
      }}
    />
    <Footer sx={{ backgroundColor: blue[300] }} />
  </>
);
export default Index;
