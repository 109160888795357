import React from 'react';
import Checkout from '../layout/Checkout';
import SEO from '../modules/seo';

const App = () => {
  return (
    <>
      <SEO
        title="Checkout | Indoaddict"
        description="Complete your booking for our exciting destination and tour packages! Our expert team is here to help you plan your next adventure and ensure a seamless checkout process."
        name="Indoaddict"
        type="Indoaddict"
      />
      <Checkout />
    </>
  );
};

export default App;
